import React from 'react';
import '../styles/Intro.css';

const content = require('../content/intro.json');

const Intro = () => (
  <div className="intro-container full-screen">
    <div className="intro">
      <h1 className="intro-subheader">{content.header}</h1>
      <ol>{content.steps.map((step) => <li key={step}><p>{step}</p></li>)}</ol>
      <p className="continue-link" onClick={() => { window.location.href = '/checklist-builder'; }}>
        Continue
        <i className="fas fa-long-arrow-alt-right continue-arrow" />
      </p>
    </div>
  </div>
);

export default Intro;

import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import ResetPasswordConfirmationForm from '../../components/auth/ResetPasswordConfirmationForm';

const content = require('../../content/auth.json');

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    const { email } = props;
    this.state = {
      code: '',
      email,
      password: '',
      confirmPassword: '',
      isConfirming: false,
    };
  }

  validateForm = () => {
    const { code, password, confirmPassword } = this.state;
    return (code.length > 0 && password.length > 0 && password === confirmPassword);
  }

  handleChange = (event) => this.setState({ [event.target.id]: event.target.value });

  handleSubmit = async (event) => {
    event.preventDefault();
    const { switchToPage } = this.props;
    const { email, code, password } = this.state;
    this.setState({ isConfirming: true });
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      switchToPage('LogIn', true);
    } catch (e) {
      if (e.code === 'CodeMismatchException') {
        alert(content.errors.incorrectCode);
      } else if (e.code === 'InvalidParameterException') {
        alert(content.errors.insecurePassword);
      } else {
        alert(e.message);
      }
      this.setState({ isConfirming: false });
    }
  };

  render() {
    const { headerClass } = this.props;
    const {
      password, confirmPassword, code, isConfirming,
    } = this.state;
    return (
      <ResetPasswordConfirmationForm
        password={password}
        confirmPassword={confirmPassword}
        code={code}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        validateForm={this.validateForm}
        isConfirming={isConfirming}
        headerClass={headerClass}
      />
    );
  }
}

import React, { Component } from 'react';
import { API } from 'aws-amplify';
import ChecklistBuilderFlow from './ChecklistBuilderFlow';
import Checklist from './Checklist';

export default class ChecklistBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      this.setState({ isLoading: false });
    } else {
      this.getUserInfo();
    }
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props;
    if (prevProps.isAuthenticated !== isAuthenticated) {
      this.getUserInfo();
    }
  }

  getUserInfo = () => {
    const { updateProgressState } = this.props;
    API.get('zwc-user-api', '/user-info').then((userInfo) => {
      updateProgressState(userInfo);
      this.setState({ isLoading: false });
    });
  }

  render() {
    const { isAuthenticated, progressStage, updateProgressState } = this.props;
    const { isLoading } = this.state;
    if (!isLoading) {
      switch (progressStage) {
        case 'startedProblemsFlow': {
          return (
            <ChecklistBuilderFlow
              isAuthenticated
              updateProgressState={updateProgressState}
            />
          );
        }
        default: {
          return (
            <Checklist
              isAuthenticated={isAuthenticated}
              updateProgressState={updateProgressState}
              progressStage={progressStage}
            />
          );
        }
      }
    }
    return <div />;
  }
}

import { API } from 'aws-amplify';

export function initializeProgress() {
  return API.post('zwc-user-api', '/user-info', {
    body: {
      progressStage: 'startedProblemsFlow',
    },
  });
}

export function updateUserInfo(updatedProgress) {
  return API.put('zwc-user-api', '/user-info', {
    body: updatedProgress,
  });
}

export function sortProblems(problems) {
  return problems.sort(
    (a, b) => (a.problemName.toLowerCase() < b.problemName.toLowerCase() ? -1 : 1),
  );
}

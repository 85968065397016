import React, { Component } from 'react';
import ContactForm from '../components/ContactForm';

const content = require('../content/contact.json');

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageHeader: content.header,
      pageSubheader: content.subheader,
      name: '',
      email: '',
      message: '',
      isSending: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  }

  validateForm = () => {
    const { name, email, message } = this.state;
    return name.length > 0 && email.length > 0 && message.length > 0;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, email, message } = this.state;
    this.setState({ isSending: true });
    fetch('https://aiikn63n03.execute-api.us-east-1.amazonaws.com/prod/email/send', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name,
        message,
        userEmail: email,
        clientEmail: 'zerowastechecklist@gmail.com',
        siteDomain: window.location.origin,
      }),
    }).then((response) => response.json()).then((json) => {
      if (json.MessageId) {
        this.setState({
          name: '',
          email: '',
          message: '',
          isSending: false,
          pageHeader: content.header2,
          pageSubheader: content.subheader2,
        });
      } else {
        window.alert(content.error);
      }
    });
  }

  render() {
    const {
      pageHeader, pageSubheader, name, email, message, isSending,
    } = this.state;
    return (
      <ContactForm
        pageHeader={pageHeader}
        pageSubheader={pageSubheader}
        name={name}
        email={email}
        message={message}
        isSending={isSending}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        validateForm={this.validateForm}
      />
    );
  }
}

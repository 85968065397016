import React from 'react';
import '../styles/Snackbar.css';

export default (props) => {
  const { message, backgroundColor, visible } = props;
  return (
    <div className={`snackbar-container${visible ? ' visible' : ''}`}>
      <div className="snackbar" style={{ backgroundColor }}>{message}</div>
    </div>
  );
};

import React, { Component } from 'react';
import Card from './Card';
import ItemDetailsModal from './modals/ItemDetailsModal';

export default class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showItemDetailsModal: false,
      cardDetails: '',
    };
  }

  onCardClick = (cardId) => {
    const { selectedCardIds, updateSelectedCardIds } = this.props;
    const indexOfCardId = selectedCardIds.indexOf(cardId);
    const newSelected = selectedCardIds;
    if (indexOfCardId === -1) {
      newSelected.push(cardId);
    } else {
      newSelected.splice(indexOfCardId, 1);
    }
    updateSelectedCardIds(selectedCardIds);
  }

  render() {
    const { cards, selectedCardIds } = this.props;
    const { showItemDetailsModal, cardDetails } = this.state;
    return (
      <div style={{ paddingBottom: '120px' }}>
        <div id="cards" className="cards">
          {cards.map((card) => (
            <Card
              key={card.key}
              title={card.title}
              onClick={() => this.onCardClick(card.key)}
              selected={selectedCardIds.includes(card.key)}
              detailsText={card.detailsText}
              showDetailsModal={() => this.setState({
                showItemDetailsModal: true,
                cardDetails: card.details,
              })}
            />
          ))}
        </div>
        <ItemDetailsModal
          show={showItemDetailsModal}
          hide={() => this.setState({ showItemDetailsModal: false })}
          text={cardDetails}
        />
      </div>
    );
  }
}

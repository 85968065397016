import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import SignupForm from '../../components/auth/SignupForm';

const content = require('../../content/auth.json');

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password, switchToPage } = this.props;
    this.setState({ isLoading: true });
    try {
      await Auth.signUp({ username: email, password });
      switchToPage('SignUpConfirmation');
    } catch (e) {
      if (e.code === 'InvalidParameterException') {
        alert(content.errors.insecurePassword);
      } else {
        alert(e.message);
      }
    }
    this.setState({ isLoading: false });
  }

  validateForm = () => {
    const { email, password, confirmPassword } = this.props;
    return (
      email.length > 0
      && password.length > 0
      && password === confirmPassword
    );
  }

  render() {
    const {
      handleChange,
      switchToPage,
      email,
      password,
      confirmPassword,
      headerClass,
    } = this.props;
    const { isLoading } = this.state;
    return (
      <SignupForm
        email={email}
        password={password}
        confirmPassword={confirmPassword}
        handleChange={handleChange}
        handleSubmit={this.handleSubmit}
        validateForm={this.validateForm}
        switchToPage={switchToPage}
        isLoading={isLoading}
        headerClass={headerClass}
      />
    );
  }
}

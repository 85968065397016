const dev = {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://wxp9cssjs8.execute-api.us-east-1.amazonaws.com/dev',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_thFEcfVIt',
    APP_CLIENT_ID: '3b2a15p82njna9m29mvl1f4pu1',
    IDENTITY_POOL_ID: 'us-east-1:92fb2c80-ca5f-42f7-abab-b98174626996',
  },
};

const prod = {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://wh4f51v8t9.execute-api.us-east-1.amazonaws.com/prod',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_QNpLl4AD9',
    APP_CLIENT_ID: '1n1q82jbobv6ac8vgs1ln05bd5',
    IDENTITY_POOL_ID: 'us-east-1:b4a4f2f1-a575-4c95-97eb-078b4d663c32',
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  cloudfront: {
    URL: 'http://d2mpvycrjkuiv6.cloudfront.net/',
  },
  sanity: {
    URL: 'https://kpu3h10o.apicdn.sanity.io/v1/graphql/production/default',
  },
  ...config,
};

import { API } from 'aws-amplify';
import { initializeProgress } from './Api';

export function getFormFieldSize() {
  return window.innerWidth > 480 ? 'large' : undefined;
}

function checkForUppercaseWords(str) {
  const uppercaseWords = ['ups', 'lasik'];
  const words = str.split(' ');
  const wordsWithSpecialUppercase = words.map((word) => (
    uppercaseWords.includes(word.toLowerCase()) ? word.toUpperCase() : word
  ));
  return wordsWithSpecialUppercase.join(' ');
}

export function capitalizeFirstLetter(str) {
  return checkForUppercaseWords(str.charAt(0).toUpperCase() + str.substring(1));
}

export function makeTitleCase(str) {
  const nonCapitalizedWords = ['and', 'or', 'in', 'on', 'with', 'for', 'at'];
  const words = str.split(' ');
  const capitalizedWords = words.map((word) => (
    nonCapitalizedWords.includes(word) ? word : capitalizeFirstLetter(word)
  ));
  return capitalizedWords.join(' ');
}

export function addProblemToChecklist(problem, userProblems, userProblem) {
  const body = { problemId: problem.problemId, checked: false, inExceptionsList: false };
  let updatedUserProblems = userProblems;
  if (userProblem) {
    API.put('zwc-user-api', `/user-problem/${problem.problemId}`, { body });
    const index = userProblems.findIndex(
      (userProblemInList) => userProblemInList.problemId === problem.problemId,
    );
    updatedUserProblems.splice(index, 1, body);
  } else {
    API.post('zwc-user-api', '/user-problem', { body });
    updatedUserProblems = userProblems.concat(body);
  }
  return {
    userProblems: updatedUserProblems,
    snackbar: {
      visible: true,
      message: 'Problem added to checklist',
      color: 'var(--dark-blue)',
    },
  };
}

export function removeProblemFromChecklist(problem, userProblems, exceptionBody) {
  const index = userProblems.findIndex(
    (userProblemInList) => userProblemInList.problemId === problem.problemId,
  );
  const updatedUserProblems = userProblems;
  if (exceptionBody) {
    API.put('zwc-user-api', `/user-problem/${problem.problemId}`, { body: exceptionBody });
    updatedUserProblems.splice(index, 1, exceptionBody);
  } else {
    API.del('zwc-user-api', `/user-problem/${problem.problemId}`);
    updatedUserProblems.splice(index, 1);
  }
  return {
    userProblems: updatedUserProblems,
    snackbar: {
      visible: true,
      message: exceptionBody ? 'Problem added to exceptions list' : 'Problem removed from checklist',
      color: 'var(--dark-gray)',
    },
  };
}

function getUnassumedProblemsForCategory(categoryId, problems) {
  return problems.filter((problem) => (
    problem.categoryId === categoryId && !problem.assumed
  ));
}

function getUnassumedSubcategories(categoryId, categories) {
  return categories.filter((category) => (
    category.parentCategoryId === categoryId && !category.assumed
  ));
}

export function shouldDisplayCategory(categoryId, problems, categories) {
  return getUnassumedProblemsForCategory(categoryId, problems).length > 0
    || getUnassumedSubcategories(categoryId, categories).length > 0;
}

export function shouldDisplaySubcategory(subcategoryId, problems) {
  return getUnassumedProblemsForCategory(subcategoryId, problems).length > 0;
}

export function userProblemExists(problemId, userProblems) {
  return userProblems.find((userProblem) => userProblem.problemId === problemId);
}

export async function handleUserAuth(updateProgressState, userHasAuthenticated, closeModal) {
  try {
    await API.get('zwc-user-api', '/user-info');
  } catch (e) {
    await initializeProgress();
    await updateProgressState({ progressStage: 'startedProblemsFlow' });
  }
  userHasAuthenticated(true);
  if (closeModal) {
    closeModal();
  }
}

export function subscribeToMailingList(email) {
  API.post('zwc-user-api', '/subscribe', { body: { email } });
}

import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import LoaderButton from '../LoaderButton';
import { getFormFieldSize } from '../../utils/Resources';

const content = require('../../content/auth.json');

export default (props) => {
  const {
    confirmationCode,
    handleChange,
    handleSubmit,
    validateForm,
    isLoading,
    headerClass,
  } = props;
  const bsSize = getFormFieldSize();
  return (
    <div className="signup">
      <h1 className={`auth-header ${headerClass}`}>{content.confirmationCodeHeader}</h1>
      <p className="auth-subheader">{content.confirmationCodeSubheader}</p>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="confirmationCode" bsSize={bsSize}>
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={confirmationCode}
            onChange={handleChange}
          />
        </FormGroup>
        <LoaderButton
          className="auth-button"
          block
          bsSize={bsSize}
          disabled={!validateForm()}
          type="submit"
          isLoading={isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    </div>
  );
};

import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import SignupConfirmationForm from '../../components/auth/SignupConfirmationForm';
import { handleUserAuth, subscribeToMailingList } from '../../utils/Resources';

const content = require('../../content/auth.json');

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmationCode: '',
    };
  }

  handleChange = (event) => this.setState({ confirmationCode: event.target.value });

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      userHasAuthenticated, updateProgressState, switchToPage, email, password, closeModal,
    } = this.props;
    const { confirmationCode } = this.state;
    this.setState({ isLoading: true });
    try {
      await Auth.confirmSignUp(email, confirmationCode);
      await Auth.signIn(email, password);
      subscribeToMailingList(email);
      handleUserAuth(updateProgressState, userHasAuthenticated, closeModal);
    } catch (e) {
      if (e.code === 'NotAuthorizedException') {
        switchToPage('LogIn', false);
      } else if (e.code === 'CodeMismatchException') {
        alert(content.errors.incorrectCode);
      } else {
        alert(e.message);
      }
      this.setState({ isLoading: false });
    }
  }

  validateForm = () => {
    const { confirmationCode } = this.state;
    return confirmationCode.length > 0;
  }

  render() {
    const { headerClass } = this.props;
    const { confirmationCode, isLoading } = this.state;
    return (
      <SignupConfirmationForm
        confirmationCode={confirmationCode}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        validateForm={this.validateForm}
        isLoading={isLoading}
        headerClass={headerClass}
      />
    );
  }
}

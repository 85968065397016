import React from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import '../styles/NavBar.css';

const NavBar = (props) => {
  const { onHomePage, isAuthenticated, handleLogout } = props;
  return (
    <Navbar fluid collapseOnSelect className={`navbar-fixed-top${onHomePage ? ' transparent' : ''}`}>
      <Navbar.Header>
        <Navbar.Brand>
          <Link to="/">
            <img src="/brand.png" alt="Home" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight>
          <LinkContainer to="/about">
            <NavItem>About</NavItem>
          </LinkContainer>
          <LinkContainer to="/items">
            <NavItem>Items</NavItem>
          </LinkContainer>
          <LinkContainer to="/checklist">
            <NavItem>Checklist</NavItem>
          </LinkContainer>
          <LinkContainer to="/blog">
            <NavItem>Blog</NavItem>
          </LinkContainer>
          <LinkContainer to="/contact">
            <NavItem>Contact</NavItem>
          </LinkContainer>
          {isAuthenticated ? <NavItem onClick={handleLogout}>Logout</NavItem> : (
            <LinkContainer to="/login">
              <NavItem>Login</NavItem>
            </LinkContainer>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;

import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { getFormFieldSize } from '../../utils/Resources';
import LoaderButton from '../LoaderButton';

export default class DeleteItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = { isDeleting: false };
  }

  deleteItem = (event) => {
    event.preventDefault();
    this.setState({ isDeleting: true });
    const { deleteItem } = this.props;
    deleteItem();
    this.setState({ isDeleting: false });
  }

  render() {
    const { show, hide, itemType } = this.props;
    const { isDeleting } = this.state;
    const bsSize = getFormFieldSize();
    return (
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>{`Are you sure you want to remove this ${itemType} from your checklist?`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.deleteItem}>
            <div className="save-custom-container">
              <LoaderButton
                className="delete-button"
                block
                bsSize={bsSize}
                type="submit"
                text="Remove"
                loadingText="Removing..."
                isLoading={isDeleting}
                disabled={isDeleting}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

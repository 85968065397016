import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import config from '../config';
import '../styles/About.css';

const content = require('../content/about.json');

export default () => (
  <div className="info-page">
    {window.innerWidth >= 1000 ? (
      <table>
        <tbody>
          {content.paragraphs.map((paragraph, index) => (
            <tr key={paragraph.header}>
              {index % 2 === 0 && (
                <td className="about-column">
                  <img src={`${config.cloudfront.URL}${paragraph.image}`} alt={paragraph.image} className="about-image" />
                </td>
              )}
              <td className="about-column">
                <h1>{paragraph.header}</h1>
                <p>{ReactHTMLParser(paragraph.body)}</p>
              </td>
              {index % 2 === 1 && (
                <td className="about-column">
                  <img src={`${config.cloudfront.URL}${paragraph.image}`} alt={paragraph.image} className="about-image" />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div>
        {content.paragraphs.map((paragraph) => (
          <div key={paragraph.header}>
            <h1 className="about-header-mobile">{paragraph.header}</h1>
            <img src={`${config.cloudfront.URL}${paragraph.image}`} alt={paragraph.image} className="about-image" />
            <p className="about-paragraph-mobile">{ReactHTMLParser(paragraph.body)}</p>
          </div>
        ))}
      </div>
    )}
  </div>
);

import React from 'react';
import { Modal } from 'react-bootstrap';

const content = require('../../content/modals.json');

export default (props) => {
  const { show, hide } = props;
  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title className="preview-modal-title">{content.enteringFlow.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content.enteringFlow.paragraphs.map((paragraph) => <p key={paragraph}>{paragraph}</p>)}
      </Modal.Body>
    </Modal>
  );
};

import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import LoaderButton from '../LoaderButton';
import { getFormFieldSize } from '../../utils/Resources';

const content = require('../../content/auth.json');

export default (props) => {
  const {
    password,
    confirmPassword,
    code,
    handleChange,
    handleSubmit,
    validateForm,
    isConfirming,
    headerClass,
  } = props;
  const bsSize = getFormFieldSize();
  return (
    <div className="reset-password">
      <h1 className={`auth-header ${headerClass}`}>{content.confirmationCodeHeader}</h1>
      <p className="auth-subheader">{content.confirmationCodeSubheader}</p>
      <form onSubmit={handleSubmit}>
        <FormGroup bsSize={bsSize} controlId="code">
          <ControlLabel>Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={code}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup bsSize={bsSize} controlId="password">
          <ControlLabel>New password</ControlLabel>
          <FormControl
            type="password"
            value={password}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup bsSize={bsSize} controlId="confirmPassword">
          <ControlLabel>Confirm password</ControlLabel>
          <FormControl
            type="password"
            onChange={handleChange}
            value={confirmPassword}
          />
        </FormGroup>
        <LoaderButton
          className="auth-button"
          block
          type="submit"
          bsSize={bsSize}
          text="Confirm"
          loadingText="Confirming…"
          isLoading={isConfirming}
          disabled={!validateForm()}
        />
      </form>
    </div>
  );
};

import React, { Component } from 'react';
import { PageHeader, Glyphicon } from 'react-bootstrap';
import ReactHTMLParser from 'react-html-parser';
import { API } from 'aws-amplify';
import { makeTitleCase, addProblemToChecklist, removeProblemFromChecklist } from '../utils/Resources';
import RecommendationCard from '../components/RecommendationCard';
import LoginModal from '../components/modals/LoginModal';
import CreateItemModal from '../components/modals/CreateItemModal';
import EditItemModal from '../components/modals/EditItemModal';
import DeleteItemModal from '../components/modals/DeleteItemModal';
import Snackbar from '../components/Snackbar';
import '../styles/ProblemPage.css';
import { getProblemByName } from '../utils/Sanity';

export default class ProblemPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProblems: [],
      problem: {},
      userProblem: {},
      solutionsForProblem: [],
      customSolutions: [],
      userSolutions: [],
      recommendationsForSolutions: {},
      isLoading: true,
      showLoginModal: false,
      snackbar: {
        visible: false,
        message: '',
        color: undefined,
      },
      solutionIdToAdd: undefined,
      showCreateSolutionModal: false,
      showEditSolutionModal: false,
      showDeleteSolutionModal: false,
      selectedSolution: undefined,
    };
  }

  async componentDidMount() {
    const { match, isAuthenticated } = this.props;
    try {
      const { category: categoryName, problem: problemName } = match.params;
      const problem = await getProblemByName(
        categoryName.toLowerCase().replace(/_/g, ' '),
        problemName.toLowerCase().replace(/_/g, ' '),
      );
      let userProblems = [];
      let userSolutions = [];
      let customSolutions = [];
      if (isAuthenticated) {
        ([userProblems, userSolutions, customSolutions] = await Promise.all([
          API.get('zwc-user-api', '/user-problems'),
          API.get('zwc-user-api', '/user-solutions'),
          API.get('zwc-user-api', `/user-custom-solutions/${problem.problemId}`),
        ]));
      }
      let sortedCustomSolutions;
      if (isAuthenticated) {
        sortedCustomSolutions = customSolutions.sort(
          (a, b) => (a.solutionName.toLowerCase() < b.solutionName.toLowerCase() ? -1 : 1),
        );
      }
      let userProblem;
      if (userProblems) {
        userProblem = userProblems.find(
          (userProblemInList) => userProblemInList.problemId === problem.problemId,
        );
      }
      const zeroWasteSolutionsForProblem = problem.solutions.filter(
        (solution) => !solution.lowWaste,
      );
      const sortedSolutionsForProblem = zeroWasteSolutionsForProblem.sort(
        (a, b) => (a.solutionName < b.solutionName ? -1 : 1),
      );
      const recommendationsForSolutions = {};
      sortedSolutionsForProblem.forEach((solution) => {
        recommendationsForSolutions[solution.solutionId] = solution.recommendations.sort((a, b) => (
          a.recommendationName.toLowerCase() < b.recommendationName.toLowerCase() ? -1 : 1
        ));
      });
      this.setState({
        userProblems,
        problem,
        userProblem,
        solutionsForProblem: sortedSolutionsForProblem,
        customSolutions: sortedCustomSolutions,
        userSolutions: userSolutions || [],
        recommendationsForSolutions,
        isLoading: false,
      });
    } catch (e) {
      window.location.href = '/page-not-found';
    }
  }

  async componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props;
    const { problem, solutionIdToAdd } = this.state;
    if (isAuthenticated && !prevProps.isAuthenticated) {
      this.setState({ isLoading: true });
      const [userProblems, userSolutions, customSolutions] = await Promise.all([
        API.get('zwc-user-api', '/user-problems'),
        API.get('zwc-user-api', '/user-solutions'),
        API.get('zwc-user-api', `/user-custom-solutions/${problem.problemId}`),
      ]);
      const sortedCustomSolutions = customSolutions.sort(
        (a, b) => (a.solutionName.toLowerCase() < b.solutionName.toLowerCase() ? -1 : 1),
      );
      const userProblem = userProblems.find(
        (userProblemInList) => userProblemInList.problemId === problem.problemId,
      );
      await this.setState({
        userProblem,
        userSolutions,
        customSolutions: sortedCustomSolutions,
        userProblems,
        isLoading: false,
      });
      if (solutionIdToAdd) {
        const userSolutionIds = userSolutions.map((userSolution) => userSolution.solutionId);
        if (!userSolutionIds.includes(solutionIdToAdd)) {
          this.addSolutionToChecklist(solutionIdToAdd);
        }
        this.setState({ solutionIdToAdd: undefined });
      } else {
        this.addProblemToChecklist();
      }
    }
  }

  addProblemToChecklist = () => {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      const { problem, userProblem, userProblems } = this.state;
      const { userProblems: updatedUserProblems, snackbar } = addProblemToChecklist(
        problem,
        userProblems,
        userProblem,
      );
      const newUserProblem = updatedUserProblems.find(
        (problemInList) => problemInList.problemId === problem.problemId,
      );
      this.setState({ userProblems: updatedUserProblems, userProblem: newUserProblem, snackbar });
      setTimeout(() => this.setState({ snackbar: { visible: false, message: '' } }), 3000);
    } else {
      this.setState({ showLoginModal: true });
    }
  }

  removeProblemFromChecklist = () => {
    const { problem, userProblems } = this.state;
    const { userProblems: updatedUserProblems, snackbar } = removeProblemFromChecklist(
      problem,
      userProblems,
    );
    this.setState({ userProblems: updatedUserProblems, userProblem: undefined, snackbar });
    setTimeout(() => this.setState({ snackbar: { visible: false, message: '' } }), 3000);
  }

  makeException = () => {
    const { problem, userProblems } = this.state;
    const userProblem = { problemId: problem.problemId, checked: false, inExceptionsList: true };
    const { userProblems: updatedUserProblems, snackbar } = removeProblemFromChecklist(
      problem,
      userProblems,
      userProblem,
    );
    this.setState({ userProblems: updatedUserProblems, userProblem, snackbar });
    setTimeout(() => this.setState({ snackbar: { visible: false, message: '' } }), 3000);
  }

  addSolutionToChecklist = (solutionId) => {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.addProblemToChecklist();
      API.post('zwc-user-api', '/user-solution', { body: { solutionId } });
      this.setState((prevState) => ({
        userSolutions: [...prevState.userSolutions, { solutionId }],
        snackbar: {
          visible: true,
          message: 'Solution saved to checklist',
          color: 'var(--dark-blue)',
        },
      }));
      setTimeout(() => this.setState({ snackbar: { visible: false, message: '' } }), 3000);
    } else {
      this.setState({ showLoginModal: true, solutionIdToAdd: solutionId });
    }
  }

  removeSolutionFromChecklist = (solutionId) => {
    const { userSolutions } = this.state;
    const userSolutionIds = userSolutions.map((userSolution) => userSolution.solutionId);
    API.del('zwc-user-api', `/user-solution/${solutionId}`);
    userSolutions.splice(userSolutionIds.indexOf(solutionId), 1);
    this.setState({
      userSolutions,
      snackbar: {
        visible: true,
        message: 'Solution removed from checklist',
        color: 'var(--dark-gray)',
      },
    });
    setTimeout(() => this.setState({ snackbar: { visible: false, message: '' } }), 3000);
  }

  createCustomSolution = async (solutionName) => {
    const { problem, customSolutions, userSolutions } = this.state;
    const solution = await API.post('zwc-user-api', '/user-custom-solution', {
      body: { solutionName, problemId: problem.problemId },
    });
    const userSolution = await API.post('zwc-user-api', '/user-solution', {
      body: { solutionId: solution.solutionId },
    });
    const updatedCustomSolutions = customSolutions.concat(solution).sort(
      (a, b) => (a.solutionName.toLowerCase() < b.solutionName.toLowerCase() ? -1 : 1),
    );
    return this.setState({
      showCreateSolutionModal: false,
      customSolutions: updatedCustomSolutions,
      userSolutions: userSolutions.concat(userSolution),
    });
  }

  updateCustomSolution = async (solutionName) => {
    const { customSolutions, selectedSolution } = this.state;
    await API.put('zwc-user-api', `/user-custom-solution/${selectedSolution.solutionId}`, {
      body: { solutionName },
    });
    const index = customSolutions.findIndex(
      (solutionInList) => solutionInList.solutionId === selectedSolution.solutionId,
    );
    const [oldSolution] = customSolutions.splice(index, 1);
    const updatedCustomSolutions = customSolutions.concat({ ...oldSolution, solutionName }).sort(
      (a, b) => (a.solutionName.toLowerCase() < b.solutionName.toLowerCase() ? -1 : 1),
    );
    this.setState({
      showEditSolutionModal: false,
      selectedSolution: undefined,
      customSolutions: updatedCustomSolutions,
    });
  }

  deleteCustomSolution = async () => {
    const { selectedSolution, customSolutions, userSolutions } = this.state;
    await Promise.all([
      API.del('zwc-user-api', `/user-solution/${selectedSolution.solutionId}`),
      API.del('zwc-user-api', `/user-custom-solution/${selectedSolution.solutionId}`),
    ]);
    customSolutions.splice(
      customSolutions.findIndex((solution) => solution.solutionId === selectedSolution.solutionId),
      1,
    );
    userSolutions.splice(
      userSolutions.findIndex((solution) => solution.solutionId === selectedSolution.solutionId),
      1,
    );
    return this.setState({
      showDeleteSolutionModal: false,
      selectedSolution: undefined,
      customSolutions,
      userSolutions,
    });
  }

  renderSolutions = () => {
    const { solutionsForProblem, userSolutions, recommendationsForSolutions } = this.state;
    const userSolutionIds = userSolutions.map((userSolution) => userSolution.solutionId);
    return solutionsForProblem.map((solution) => {
      const userHasSolution = userSolutionIds.includes(solution.solutionId);
      const onClick = userHasSolution
        ? this.removeSolutionFromChecklist
        : this.addSolutionToChecklist;
      const recommendationsForSolution = recommendationsForSolutions[solution.solutionId];
      return (
        <div key={solution.solutionId}>
          <h4 className="solution-name">
            {makeTitleCase(solution.solutionName)}
            <i
              className={`${userHasSolution ? 'fas' : 'far'} fa-heart`}
              onClick={() => onClick(solution.solutionId)}
            />
          </h4>
          <p>{ReactHTMLParser(solution.description)}</p>
          {recommendationsForSolution.length > 0 && (
            <div className="product-recommendations">
              <p className="product-recommendations-label">Product Recommendations:</p>
              <div className="product-recommendation-cards">
                {recommendationsForSolution.map((recommendation) => (
                  <RecommendationCard
                    key={recommendation.recommendationId}
                    title={recommendation.recommendationName}
                    link={recommendation.link}
                    image={recommendation.image}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  renderCustomSolutions = () => {
    const { customSolutions, userSolutions } = this.state;
    const userSolutionIds = userSolutions.map((solution) => solution.solutionId);
    const userCustomSolutions = customSolutions.filter(
      (solution) => userSolutionIds.includes(solution.solutionId),
    );
    return (
      <div className="custom-solutions-container">
        {userCustomSolutions.length > 0 && (
          <div className="custom-solutions">
            <h4 className="solution-name">My Solutions</h4>
            <span className="custom-solutions-helper-text">(only visible to you)</span>
            <ul>
              {userCustomSolutions.map((solution) => (
                <li key={solution.solutionId}>
                  {solution.solutionName}
                  <i
                    className="fas fa-pencil-alt"
                    onClick={() => this.setState({
                      showEditSolutionModal: true, selectedSolution: solution,
                    })}
                  />
                  <i
                    className="fas fa-trash"
                    onClick={() => this.setState({
                      showDeleteSolutionModal: true, selectedSolution: solution,
                    })}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        <p className="create-solution-link" onClick={() => this.setState({ showCreateSolutionModal: true })}>
          <i className="fas fa-plus" />
          {userCustomSolutions.length > 0 ? 'Add another solution' : 'Add your own solution'}
        </p>
      </div>
    );
  }

  renderNoSolutions = () => {
    const { problem } = this.state;
    return (
      <p>
        {`Currently, there are no known zero-waste alternatives for ${problem.problemName}. 
        Please check back later as we are always adding new zero-waste solutions to the site.`}
      </p>
    );
  }

  render() {
    const { isAuthenticated } = this.props;
    const {
      problem,
      userProblem,
      solutionsForProblem,
      customSolutions,
      isLoading,
      showLoginModal,
      snackbar,
      showCreateSolutionModal,
      showEditSolutionModal,
      showDeleteSolutionModal,
      selectedSolution,
    } = this.state;
    const showAddProblemLink = !userProblem || userProblem.inExceptionsList;
    const customSolutionNames = customSolutions ? (
      customSolutions.map((solution) => solution.solutionName.toLowerCase())
    ) : [];
    return isLoading ? <Glyphicon glyph="refresh" className="spinning page-spinner" /> : (
      <div className="static-problem-page">
        <PageHeader>{`Zero-Waste Solutions for ${makeTitleCase(problem.problemName)}`}</PageHeader>
        {showAddProblemLink && (
          <div className="add-problem-link">
            <p onClick={this.addProblemToChecklist}>
              <i className="fas fa-plus" />
              Add this problem to my checklist
            </p>
          </div>
        )}
        <h3 className={`problem-explanation-header${showAddProblemLink ? ' low-top-margin' : ''}`}>
          Why is this a problem?
        </h3>
        <p>{ReactHTMLParser(problem.explanation)}</p>
        <h3 className="solutions-header">What are the solutions?</h3>
        {solutionsForProblem.length > 0 ? this.renderSolutions() : this.renderNoSolutions()}
        {isAuthenticated && userProblem && this.renderCustomSolutions()}
        {!showAddProblemLink && (
          <div>
            <div className="remove-problem-link">
              <p onClick={this.removeProblemFromChecklist}>Remove this problem from my checklist</p>
            </div>
            <div className="make-exception-link">
              <p onClick={this.makeException}> or make an exception for this problem</p>
            </div>
          </div>
        )}
        <Snackbar
          visible={snackbar.visible}
          message={snackbar.message}
          backgroundColor={snackbar.color}
        />
        <LoginModal
          showModal={showLoginModal}
          hideModal={() => this.setState({ showLoginModal: false })}
          {...this.props}
        />
        <CreateItemModal
          show={showCreateSolutionModal}
          hide={() => this.setState({ showCreateSolutionModal: false })}
          itemType="solution"
          itemsOnPage={customSolutionNames}
          createItem={this.createCustomSolution}
        />
        <EditItemModal
          show={showEditSolutionModal}
          hide={() => this.setState({ showEditSolutionModal: false })}
          itemType="solution"
          name={selectedSolution ? selectedSolution.solutionName : ''}
          itemsOnPage={customSolutionNames} // TODO check itemsOnPage in EditItemModal
          updateItemName={this.updateCustomSolution}
        />
        <DeleteItemModal
          show={showDeleteSolutionModal}
          hide={() => this.setState({ showDeleteSolutionModal: false })}
          itemType="solution"
          deleteItem={this.deleteCustomSolution}
        />
      </div>
    );
  }
}

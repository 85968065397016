import React, { Component } from 'react';
import Login from './Login';
import Signup from './Signup';
import SignupConfirmation from './SignupConfirmation';
import ResetPassword from './ResetPassword';
import ResetPasswordConfirmation from './ResetPasswordConfirmation';

export default class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'SignUp',
      email: '',
      password: '',
      confirmPassword: '',
      passwordWasReset: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  }

  switchToPage = (pageName, passwordWasReset) => {
    if (pageName === 'LogIn') {
      this.setState((prevState) => ({
        page: 'LogIn',
        passwordWasReset,
        email: passwordWasReset ? '' : prevState.email,
        password: passwordWasReset ? '' : prevState.password,
        confirmPassword: passwordWasReset ? '' : prevState.confirmPassword,
      }));
    } else {
      this.setState({ page: pageName });
    }
  }

  render() {
    const { updateProgressState, userHasAuthenticated, hideModal } = this.props;
    const {
      page,
      email,
      password,
      confirmPassword,
      passwordWasReset,
    } = this.state;
    const commonProps = {
      handleChange: this.handleChange,
      switchToPage: this.switchToPage,
      userHasAuthenticated,
      email,
      password,
      headerClass: hideModal ? 'auth-modal-header' : '',
      closeModal: hideModal,
      updateProgressState,
    };
    return (
      <div className={`auth-container${hideModal ? ' auth-modal' : ' full-screen'}`}>
        <div className="auth">
          {page === 'LogIn' && (
            <Login
              {...commonProps}
              passwordWasReset={passwordWasReset}
            />
          )}
          {page === 'SignUp' && (
            <Signup
              {...commonProps}
              confirmPassword={confirmPassword}
            />
          )}
          {page === 'SignUpConfirmation' && (
            <SignupConfirmation
              {...commonProps}
              confirmPassword={confirmPassword}
            />
          )}
          {page === 'ResetPassword' && (
            <ResetPassword
              handleChange={this.handleChange}
              switchToPage={this.switchToPage}
              email={email}
              headerClass={hideModal ? 'auth-modal-header' : ''}
            />
          )}
          {page === 'ResetPasswordConfirmation' && (
            <ResetPasswordConfirmation
              switchToPage={this.switchToPage}
              email={email}
              headerClass={hideModal ? 'auth-modal-header' : ''}
            />
          )}
        </div>
      </div>
    );
  }
}

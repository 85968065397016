import React from 'react';
import { capitalizeFirstLetter } from '../utils/Resources';
import '../styles/Card.css';

export default (props) => {
  const {
    selected, onClick, title, detailsText, showDetailsModal,
  } = props;
  return (
    <div className="card-container">
      <div
        className={`card ${selected ? 'card-selected ' : ''} card-without-image`}
        onClick={onClick}
      >
        <span>
          <p>{capitalizeFirstLetter(title)}</p>
        </span>
      </div>
      <div className={`${detailsText ? 'card-details-link' : 'hidden-card-details-link'}`}>
        <p onClick={showDetailsModal}>{detailsText || 'Details'}</p>
      </div>
    </div>
  );
};

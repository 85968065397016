import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import LoaderButton from '../LoaderButton';
import { getFormFieldSize } from '../../utils/Resources';

const content = require('../../content/auth.json');

export default (props) => {
  const {
    email,
    password,
    confirmPassword,
    handleChange,
    handleSubmit,
    validateForm,
    switchToPage,
    isLoading,
    headerClass,
  } = props;
  const bsSize = getFormFieldSize();
  return (
    <div className="signup">
      <h1 className={`auth-header ${headerClass}`}>{content.signupHeader}</h1>
      <p className="auth-subheader">{content.signupSubheader}</p>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize={bsSize}>
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type="email"
            value={email}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize={bsSize}>
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={password}
            onChange={handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize={bsSize}>
          <ControlLabel>Confirm password</ControlLabel>
          <FormControl
            value={confirmPassword}
            onChange={handleChange}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          className="auth-button signup-button"
          block
          bsSize={bsSize}
          disabled={!validateForm()}
          type="submit"
          isLoading={isLoading}
          text="Sign up"
          loadingText="Signing up…"
        />
        <div className="continue-link auth-link" onClick={() => switchToPage('LogIn', false)}>
          Already have an account?
        </div>
        <div className="continue-link auth-link">
          <a href="/privacy-policy">Privacy Policy</a>
        </div>
      </form>
    </div>
  );
};

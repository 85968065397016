import React, { Component } from 'react';
import { PageHeader, Glyphicon, Button } from 'react-bootstrap';
import { API } from 'aws-amplify';
import Cookies from 'js-cookie';
import { getAllCategories, getAllProblems } from '../utils/Sanity';
import { capitalizeFirstLetter, makeTitleCase } from '../utils/Resources';
import WelcomeModal from '../components/modals/WelcomeModal';
import LineHeader from '../components/LineHeader';
import '../styles/Database.css';

export default class Database extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allCategories: [],
      sortedParentCategories: [],
      allProblems: [],
      isLoading: true,
      showWelcomeModal: false,
    };
  }

  async componentDidMount() {
    const { isAuthenticated, updateProgressState } = this.props;
    const promises = [getAllCategories(), getAllProblems()];
    if (isAuthenticated) {
      promises.push(API.get('zwc-user-api', '/user-info'));
    }
    const [checklistCategories, checklistProblems, userInfo] = await Promise.all(promises);
    let showWelcomeModal = true;
    if (isAuthenticated) {
      await updateProgressState(userInfo);
      showWelcomeModal = false;
    } else if (Cookies.get('zwcWelcomeModalShown')) {
      showWelcomeModal = !Cookies.get('zwcWelcomeModalShown');
    } else {
      Cookies.set('zwcWelcomeModalShown', true);
    }
    const sortedParentCategories = checklistCategories.filter(
      (category) => !category.parentCategoryId,
    );
    this.setState({
      allCategories: checklistCategories,
      sortedParentCategories,
      allProblems: checklistProblems,
      showWelcomeModal,
      isLoading: false,
    });
  }

  getSortedProblemsForCategory = (categoryId) => {
    const { allProblems } = this.state;
    return allProblems
      .filter((problem) => problem.categoryId === categoryId)
      .sort((a, b) => (a.problemName < b.problemName ? -1 : 1));
  }

  getSortedSubcategories = (parentCategoryId) => {
    const { allCategories } = this.state;
    return allCategories.filter(
      (category) => category.parentCategoryId === parentCategoryId,
    );
  }

  renderProblems = (category) => (
    <ul className="data-list-problems">
      {this.getSortedProblemsForCategory(category.categoryId).map((problem) => (
        <li className="data-list-problem" key={problem.problemId}>
          <a href={`/items/${category.categoryName.replace(/ /g, '_')}/${problem.problemName.replace(/ /g, '_')}`}>
            {capitalizeFirstLetter(problem.problemName)}
          </a>
        </li>
      ))}
    </ul>
  )

  render() {
    const { isAuthenticated, progressStage } = this.props;
    const {
      sortedParentCategories, isLoading, showWelcomeModal,
    } = this.state;
    const linkToChecklistBuilder = !isAuthenticated || progressStage === 'startedProblemsFlow';
    const linkText = 'Click any item to view its zero-waste solutions.';
    const linkText2 = 'Don\'t know where to start?';
    return (
      <div className="database-page">
        <PageHeader>The Zero Waste Database</PageHeader>
        {!isLoading && (
          <div className="database-subheader-container">
            <div className="database-subheader">{linkText}</div>
            {linkToChecklistBuilder && (
              <div className="database-subheader-2">
                <span className="survey-text">{linkText2}</span>
                <a href="/intro">
                  <Button bsSize="lg" className="survey-btn">Take the zero waste survey!</Button>
                </a>
              </div>
            )}
          </div>
        )}
        {isLoading ? <Glyphicon glyph="refresh" className="spinning page-spinner" /> : (
          <div className="data-list-container">
            {sortedParentCategories.map((category) => (
              <div className="data-list-category-section" key={category.categoryId}>
                <div><LineHeader>{category.categoryName}</LineHeader></div>
                {this.renderProblems(category)}
                {this.getSortedSubcategories(category.categoryId).map((subcategory) => (
                  <div key={subcategory.categoryId}>
                    <p className="data-list-subcategory">{makeTitleCase(subcategory.categoryName)}</p>
                    {this.renderProblems(subcategory)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        <WelcomeModal
          show={showWelcomeModal}
          hide={() => this.setState({ showWelcomeModal: false })}
        />
      </div>
    );
  }
}

import React, { Component } from 'react';
import { Glyphicon } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { updateUserInfo } from '../utils/Api';
import { getAllCategories, getAllProblems } from '../utils/Sanity';
import { shouldDisplayCategory, userProblemExists } from '../utils/Resources';
import CategoryPage from './CategoryPage';
import EnterFlowModal from '../components/modals/EnterFlowModal';

export default class ChecklistBuilderFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {},
      allCategories: [],
      userProblems: [],
      orderedCategories: [],
      allProblems: [],
      userInfo: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      return;
    }
    try {
      this.getData();
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }
  }

  dismissFlowIntroModal = () => {
    const { userInfo } = this.state;
    const updatedUserInfo = { ...userInfo, showIntro: false };
    this.setState({ userInfo: updatedUserInfo });
    return updateUserInfo(updatedUserInfo);
  }

  getData = async () => {
    const userInfo = await API.get('zwc-user-api', '/user-info');
    const { problemsFlowSectionId } = userInfo;
    this.setState({ userInfo });
    const [checklistCategories, checklistProblems, userProblems] = await Promise.all([
      getAllCategories(), getAllProblems(), API.get('zwc-user-api', '/user-problems'),
    ]);
    const sortedParentCategories = checklistCategories.filter(
      (category) => !category.parentCategoryId,
    );
    const orderedCategories = [];
    sortedParentCategories.forEach((category) => {
      const orderedSubcategories = checklistCategories.filter(
        (categoryInList) => categoryInList.parentCategoryId === category.categoryId,
      );
      orderedCategories.push({ category, orderedSubcategories });
    });
    let [category] = sortedParentCategories;
    if (problemsFlowSectionId) {
      category = checklistCategories.find((categoryInList) => (
        categoryInList.categoryId === problemsFlowSectionId
      ));
    }
    this.setState({
      allCategories: checklistCategories,
      allProblems: checklistProblems,
      userProblems,
      orderedCategories,
      category,
      userInfo,
      isLoading: false,
    });
  }

  displayNextCategory = async (currentCategory) => {
    const { allProblems, allCategories, orderedCategories } = this.state;
    const sortedParentCategories = orderedCategories.map((category) => category.category);
    let nextCategory;
    const parentCategoryId = currentCategory.parentCategoryId || currentCategory.categoryId;
    let { selectedSubcategoryIds } = await API.get('zwc-user-api', '/user-info');
    selectedSubcategoryIds = selectedSubcategoryIds || '';
    const selectedSubcategoryIdsArr = selectedSubcategoryIds.split(',');
    const sortedUserSubcategories = allCategories.filter(
      (category) => category.parentCategoryId === parentCategoryId
      && (category.assumed || selectedSubcategoryIdsArr.includes(category.categoryId)),
    );
    if (currentCategory.parentCategoryId) {
      const currentSubcategoryIndex = sortedUserSubcategories.findIndex(
        (subcategory) => subcategory.categoryId === currentCategory.categoryId,
      );
      nextCategory = sortedUserSubcategories[currentSubcategoryIndex + 1];
    } else {
      [nextCategory] = sortedUserSubcategories;
    }
    if (!nextCategory) {
      const currentParentCategoryIndex = sortedParentCategories.findIndex(
        (category) => category.categoryId === parentCategoryId,
      );
      nextCategory = sortedParentCategories[currentParentCategoryIndex + 1];
    }
    if (!nextCategory) {
      this.completeFlow();
    } else if (shouldDisplayCategory(nextCategory.categoryId, allProblems, allCategories)) {
      this.continueFlow(nextCategory);
    } else {
      await this.createProblemsForCategory(nextCategory.categoryId);
      this.displayNextCategory(nextCategory);
    }
  }

  continueFlow = async (nextCategory) => {
    const { category, userInfo } = this.state;
    const { prevProblemsFlowSectionIds } = userInfo;
    let updatedUserInfo = userInfo;
    const newPrevSectionIds = prevProblemsFlowSectionIds
      ? `${prevProblemsFlowSectionIds},${category.categoryId}`
      : category.categoryId;
    updatedUserInfo = {
      ...userInfo,
      prevProblemsFlowSectionIds: newPrevSectionIds,
      problemsFlowSectionId: nextCategory.categoryId,
    };
    await updateUserInfo(updatedUserInfo);
    await this.setState({ category: nextCategory, userInfo: updatedUserInfo, isLoading: false });
  }

  completeFlow = async () => {
    const { updateProgressState } = this.props;
    const { userInfo } = this.state;
    await Promise.all([
      updateUserInfo({ ...userInfo, progressStage: 'completedProblemsFlow' }),
      updateProgressState({ progressStage: 'completedProblemsFlow' }),
    ]);
    window.location.href = '/checklist';
  }

  createProblemsForCategory = (categoryId) => {
    const { allProblems, userProblems } = this.state;
    const problemsForCategory = allProblems.filter((problem) => problem.categoryId === categoryId);
    const promises = [];
    problemsForCategory.forEach((problem) => {
      if (!userProblemExists(problem.problemId, userProblems)) {
        promises.push(API.post('zwc-user-api', '/user-problem', {
          body: { problemId: problem.problemId, checked: false, inExceptionsList: false },
        }));
      }
    });
    return Promise.all(promises);
  }

  goBack = async () => {
    const { allCategories, userInfo } = this.state;
    const { prevProblemsFlowSectionIds } = userInfo;
    let prevCategoryIds = prevProblemsFlowSectionIds.split(',');
    const updatedLength = prevCategoryIds.length - 1;
    const prevCategoryId = prevCategoryIds.splice(updatedLength, 1)[0];
    prevCategoryIds = prevCategoryIds.join(',');
    const updatedUserInfo = {
      ...userInfo,
      prevProblemsFlowSectionIds: prevCategoryIds,
      problemsFlowSectionId: prevCategoryId,
    };
    await updateUserInfo(updatedUserInfo);
    const prevCategory = allCategories.find((category) => category.categoryId === prevCategoryId);
    await this.setState({ category: prevCategory, userInfo: updatedUserInfo });
  }

  updateUserInfo = async (updatedUserInfo) => {
    await updateUserInfo(updatedUserInfo);
    return this.setState({ userInfo: updatedUserInfo });
  }

  render() {
    const {
      userInfo, category, allProblems, isLoading,
    } = this.state;
    const { prevProblemsFlowSectionIds } = userInfo;
    return (
      <div className="full-screen">
        {isLoading ? <Glyphicon glyph="refresh" className="spinning page-spinner" /> : (
          <div>
            <CategoryPage
              category={category}
              allProblems={allProblems}
              firstCategoryInFlow={!prevProblemsFlowSectionIds || prevProblemsFlowSectionIds === ''}
              goBack={this.goBack}
              displayNextCategory={this.displayNextCategory}
              userInfo={userInfo}
              updateUserInfo={this.updateUserInfo}
            />
            <EnterFlowModal show={userInfo.showIntro} hide={this.dismissFlowIntroModal} />
          </div>
        )}
      </div>
    );
  }
}

import React, { Component } from 'react';
import { PageHeader, Glyphicon } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { getSubcategoriesForCategory } from '../utils/Sanity';
import { userProblemExists } from '../utils/Resources';
import Cards from '../components/Cards';
import '../styles/CategoryPage.css';

export default class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      subcategories: [],
      problemsForCategory: [],
      userProblems: [],
      cards: [],
      selectedCardIds: [],
    };
  }

  componentDidMount() {
    try {
      this.getData();
    } catch (e) {
      console.log(e); // eslint-disable-line no-console
    }
  }

  componentDidUpdate(prevProps) {
    const { category } = this.props;
    if (prevProps.category.categoryId !== category.categoryId) {
      try {
        this.getData();
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
      }
    }
  }

  getData = async () => {
    const { category, allProblems } = this.props;
    const { categoryId } = category;
    const [subcategories, customProblemsForCategory, userProblems] = await Promise.all([
      getSubcategoriesForCategory(categoryId),
      API.get('zwc-user-api', `/user-custom-problems/${categoryId}`),
      API.get('zwc-user-api', '/user-problems'),
    ]);
    const standardProblemsForCategory = allProblems.filter(
      (problem) => problem.categoryId === categoryId,
    );
    const taggedCustomProblemsForCategory = customProblemsForCategory.map(
      (problem) => ({ ...problem, isCustom: true }),
    );
    const problemsForCategory = standardProblemsForCategory.concat(
      taggedCustomProblemsForCategory,
    );
    this.setState({ problemsForCategory, subcategories, userProblems }, this.getCards);
  }

  getCards = () => {
    const cards = this.getSubcategoryCards().concat(this.getProblemCards());
    const sortedCards = cards.sort(
      (a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1),
    );
    return this.setState({ cards: sortedCards }, this.getInitiallySelectedCardIds);
  }

  getSubcategoryCards = () => {
    const { subcategories } = this.state;
    return subcategories
      .filter((subcategory) => !subcategory.assumed)
      .map(
        (subcategory) => ({
          key: subcategory.categoryId,
          title: subcategory.categoryName,
          detailsText: 'Why is this a problem?',
          details: subcategory.explanation,
        }),
      );
  }

  getProblemCards = () => {
    const { problemsForCategory } = this.state;
    return problemsForCategory
      .filter((problem) => !problem.assumed)
      .map(
        (problem) => ({
          key: problem.problemId,
          title: problem.problemName,
          detailsText: 'Why is this a problem?',
          details: problem.explanation,
        }),
      );
  }

  getInitiallySelectedCardIds = () => {
    const selectedCardIds = this.getInitiallySelectedSubcategoryIds().concat(
      this.getInitiallySelectedProblemIds(),
    );
    this.setState({ selectedCardIds, isLoading: false });
  }

  getInitiallySelectedSubcategoryIds = () => {
    const { userInfo } = this.props;
    const { subcategories } = this.state;
    let { selectedSubcategoryIds } = userInfo;
    selectedSubcategoryIds = selectedSubcategoryIds || '';
    const selectedSubcategoryIdsArr = selectedSubcategoryIds.split(',');
    return subcategories
      .map((subcategory) => subcategory.categoryId)
      .filter((subcategoryId) => selectedSubcategoryIdsArr.includes(subcategoryId));
  }

  getInitiallySelectedProblemIds = () => {
    const { problemsForCategory, userProblems } = this.state;
    return problemsForCategory
      .filter((problem) => !problem.assumed && userProblemExists(problem.problemId, userProblems))
      .map((problem) => problem.problemId);
  }

  goBack = async () => {
    const { goBack } = this.props;
    this.setState({ isLoading: true });
    goBack();
  }

  saveChanges = async () => {
    const {
      category, displayNextCategory, userInfo, updateUserInfo,
    } = this.props;
    const { selectedCardIds, problemsForCategory, subcategories } = this.state;
    this.setState({ isLoading: true });
    // Clean slate by deleting any existing user problems for category and subcategories
    await this.deleteProblems();
    // ...and removing any selected subcategory IDs from database
    let allSelectedSubcategoryIds = userInfo.selectedSubcategoryIds || '';
    const allSelectedSubcategoryIdsArr = allSelectedSubcategoryIds.split(',');
    subcategories.forEach((subcategory) => {
      const subcategoryIdIndex = allSelectedSubcategoryIdsArr.findIndex(
        (subcategoryId) => subcategoryId === subcategory.categoryId,
      );
      if (subcategoryIdIndex >= 0) {
        allSelectedSubcategoryIdsArr.splice(subcategoryIdIndex, 1);
      }
    });
    allSelectedSubcategoryIds = allSelectedSubcategoryIdsArr.join(',');
    let promises = [];
    if (category.assumed || category.parentCategoryId || selectedCardIds.length > 0) {
      // Create selected and assumed user problems
      const selectedProblemIds = problemsForCategory
        .filter((problem) => selectedCardIds.includes(problem.problemId))
        .map((problem) => problem.problemId);
      const assumedProblemIds = problemsForCategory
        .filter((problem) => problem.assumed).map((problem) => problem.problemId);
      const problemIdsToCreate = selectedProblemIds.concat(assumedProblemIds);
      promises = promises.concat(this.createProblems(problemIdsToCreate));
      // Add selected subcategory IDs to database
      const selectedSubcategoryIds = subcategories
        .filter((subcategory) => selectedCardIds.includes(subcategory.categoryId))
        .map((subcategory) => subcategory.categoryId);
      selectedSubcategoryIds.forEach((subcategoryId) => {
        allSelectedSubcategoryIds = allSelectedSubcategoryIds.length > 0
          ? `${allSelectedSubcategoryIds},${subcategoryId}`
          : subcategoryId;
      });
    }
    promises.push(updateUserInfo({
      ...userInfo, selectedSubcategoryIds: allSelectedSubcategoryIds,
    }));
    await Promise.all(promises);
    displayNextCategory(category);
  }

  deleteProblems = async () => {
    const { allProblems } = this.props;
    const { problemsForCategory, subcategories, userProblems } = this.state;
    const promises = [];
    const problemIds = problemsForCategory.map((problem) => problem.problemId);
    problemIds.forEach((problemId) => {
      if (userProblemExists(problemId, userProblems)) {
        promises.push(API.del('zwc-user-api', `/user-problem/${problemId}`));
      }
    });
    const subcategoryIds = subcategories.map((category) => category.categoryId);
    subcategoryIds.forEach((subcategoryId) => {
      const problemsForSubcategory = allProblems.filter(
        (problem) => problem.categoryId === subcategoryId,
      );
      problemsForSubcategory.forEach((problem) => {
        if (userProblemExists(problem.problemId, userProblems)) {
          promises.push(API.del('zwc-user-api', `/user-problem/${problem.problemId}`));
        }
      });
    });
    await Promise.all(promises);
    const updatedUserProblems = await API.get('zwc-user-api', '/user-problems');
    return this.setState({ userProblems: updatedUserProblems });
  }

  createProblems = (problemIdsToCreate) => {
    const { userProblems } = this.state;
    const promises = [];
    problemIdsToCreate.forEach((problemId) => {
      if (!userProblemExists(problemId, userProblems)) {
        promises.push(API.post('zwc-user-api', '/user-problem', {
          body: { problemId, checked: false, inExceptionsList: false },
        }));
      }
    });
    return promises;
  }

  render() {
    const { category, firstCategoryInFlow } = this.props;
    const { cards, selectedCardIds, isLoading } = this.state;
    return (
      <div className="full-screen">
        <div className="page">
          {isLoading ? <Glyphicon glyph="refresh" className="spinning page-spinner" /> : (
            <div style={{ position: 'relative', minHeight: '82vh' }}>
              <PageHeader id="page-header">{category.categoryHeader}</PageHeader>
              <Cards
                cards={cards}
                selectedCardIds={selectedCardIds}
                updateSelectedCardIds={(newCardIds) => (
                  this.setState({ selectedCardIds: newCardIds })
                )}
              />
              <div className="continue-container absolute">
                <div>
                  {!firstCategoryInFlow && (
                    <span onClick={this.goBack} className="back-link">
                      <i className="fas fa-long-arrow-alt-left back-arrow" />
                      Back
                    </span>
                  )}
                  {!firstCategoryInFlow && <span className="back-continue-divider">|</span>}
                  <span
                    onClick={this.saveChanges}
                    className="continue-link"
                  >
                    Continue
                    <i className="fas fa-long-arrow-alt-right continue-arrow" />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

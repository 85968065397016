import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import ResetPasswordForm from '../../components/auth/ResetPasswordForm';

const content = require('../../content/auth.json');

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { isSendingCode: false };
  }

  validateForm = () => {
    const { email } = this.props;
    return email.length > 0;
  }

  handleSendCodeClick = async (event) => {
    event.preventDefault();
    const { switchToPage, email } = this.props;
    this.setState({ isSendingCode: true });
    try {
      await Auth.forgotPassword(email);
      switchToPage('ResetPasswordConfirmation');
    } catch (e) {
      if (e.code === 'LimitExceededException') {
        alert(content.errors.attemptLimitReached);
      } else if (e.code === 'UserNotFoundException') {
        alert(content.errors.userDoesNotExist);
      } else if (e.code === 'InvalidParameterException') {
        alert(content.errors.userNotConfirmed);
      } else {
        alert(e.message);
      }
      this.setState({ isSendingCode: false });
    }
  };

  render() {
    const { handleChange, email, headerClass } = this.props;
    const { isSendingCode } = this.state;
    return (
      <ResetPasswordForm
        email={email}
        handleChange={handleChange}
        handleSubmit={this.handleSendCodeClick}
        validateForm={this.validateForm}
        isSendingCode={isSendingCode}
        headerClass={headerClass}
      />
    );
  }
}

import React from 'react';
import '../styles/Card.css';

export default (props) => {
  const { title, link, image } = props;
  const openLinkInNewTab = () => {
    window.open(link, '_blank');
    window.focus();
  };
  return (
    <div className="card-container recommendation-card-container">
      <div
        className="card card-with-image recommendation-card"
        onClick={openLinkInNewTab}
      >
        <img src={image} alt={title} />
        <span>
          <p>{title}</p>
          <i className="fas fa-external-link-alt" />
        </span>
      </div>
    </div>
  );
};

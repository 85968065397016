import React from 'react';
import { Modal } from 'react-bootstrap';
import Auth from '../../containers/auth/Auth';

export default (props) => {
  const { showModal, hideModal } = props;
  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton />
      <Modal.Body><Auth {...props} /></Modal.Body>
    </Modal>
  );
};

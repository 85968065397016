import config from '../config';

const RECOMMENDATION_FIELDS = `
  _id
  _createdAt
  name
  link
  image {
    asset {
      url
    }
  }
`;

function formatRecommendationData(recommendation) {
  return {
    recommendationId: recommendation._id,
    createdAt: recommendation._createdAt,
    recommendationName: recommendation.name,
    link: recommendation.link,
    image: recommendation.image.asset.url,
  };
}

const SOLUTION_FIELDS = `
  _id
  _createdAt
  name
  explanation
  lowWaste
`;

function formatSolutionData(solution) {
  const formattedData = {
    solutionId: solution._id,
    createdAt: solution._createdAt,
    solutionName: solution.name,
    description: solution.explanation,
    lowWaste: solution.lowWaste,
  };
  if (solution.recommendations) {
    formattedData.recommendations = solution.recommendations.map((recommendation) => ({
      ...formatRecommendationData(recommendation),
      solutionId: solution._id,
    }));
  }
  return formattedData;
}

const PROBLEM_FIELDS = `
  _id
  _createdAt
  name
  explanation
  assumed
`;

function formatProblemData(problem) {
  const formattedData = {
    problemId: problem._id,
    createdAt: problem._createdAt,
    problemName: problem.name,
    explanation: problem.explanation,
    assumed: problem.assumed,
  };
  if (problem.solutions) {
    formattedData.solutions = problem.solutions.map((solution) => ({
      ...formatSolutionData(solution),
      problemId: problem._id,
    }));
  }
  return formattedData;
}

const CATEGORY_FIELDS = `
  _id
  _createdAt
  name
  header
  assumed
  explanation
`;

function formatCategoryData(category) {
  const formattedData = {
    categoryId: category._id,
    createdAt: category._createdAt,
    categoryName: category.name,
    categoryHeader: category.header,
    assumed: category.assumed,
    explanation: category.explanation,
  };
  if (category.problems) {
    formattedData.problems = category.problems.map((problem) => ({
      ...formatProblemData(problem),
      categoryId: category._id,
    }));
  }
  if (category.subcategories) {
    formattedData.subcategories = category.subcategories.map((subcategory) => ({
      ...formatCategoryData(subcategory),
      parentCategoryId: category._id,
    }));
  }
  return formattedData;
}

function fetchData(query) {
  return fetch(config.sanity.URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query }),
  }).then((res) => res.json()).then(({ data }) => data);
}

export async function getAllCategories() {
  const parentCategories = (await fetchData(`
    query {
      Categories(id: "categories") {
        categories {
          ${CATEGORY_FIELDS}
          subcategories {
            ${CATEGORY_FIELDS}
          }
        }
      }
    }
  `)).Categories.categories;
  const allCategories = [];
  parentCategories.forEach((parentCategory) => {
    allCategories.push(formatCategoryData(parentCategory));
    parentCategory.subcategories.forEach((subcategory) => {
      allCategories.push({
        ...formatCategoryData(subcategory),
        parentCategoryId: parentCategory._id,
      });
    });
  });
  return allCategories;
}

export async function getProblemByName(categoryName, problemName) {
  const allCategories = (await fetchData(`
    query {
      allCategory {
        _id
        name
        problems {
          ${PROBLEM_FIELDS}
          solutions {
            ${SOLUTION_FIELDS}
            recommendations {
              ${RECOMMENDATION_FIELDS}
            }
          }
        }
      }
    }
  `)).allCategory;
  let problem;
  allCategories.forEach((category) => {
    if (category.name.toLowerCase() === categoryName) {
      category.problems.forEach((problemInCategory) => {
        if (problemInCategory.name === problemName) {
          problem = {
            ...formatProblemData(problemInCategory),
            categoryId: category._id,
          };
        }
      });
    }
  });
  return problem;
}

export async function getSubcategoriesForCategory(categoryId) {
  return (await fetchData(`
    query {
      Category(id: "${categoryId}") {
        subcategories {
          ${CATEGORY_FIELDS}
        }
      }
    }
  `)).Category.subcategories.map((subcategory) => ({
    ...formatCategoryData(subcategory),
    parentCategoryId: categoryId,
  }));
}

export async function getAllProblems() {
  const allCategories = (await fetchData(`
    query {
      allCategory {
        _id
        problems {
          ${PROBLEM_FIELDS}
        }
      }
    }
  `)).allCategory;
  const allProblemIDs = [];
  const allProblems = [];
  allCategories.forEach((category) => {
    category.problems.forEach((problem) => {
      if (!allProblemIDs.includes(problem._id)) {
        allProblemIDs.push(problem._id);
        allProblems.push({
          ...formatProblemData(problem),
          categoryId: category._id,
        });
      }
    });
  });
  return allProblems;
}

export async function getAllSolutions() {
  const allProblems = (await fetchData(`
    query {
      allProblem {
        _id
        solutions {
          ${SOLUTION_FIELDS}
        }
      }
    }
  `)).allProblem;
  const allSolutionIDs = [];
  const allSolutions = [];
  allProblems.forEach((problem) => {
    problem.solutions.forEach((solution) => {
      if (!allSolutionIDs.includes(solution._id)) {
        allSolutionIDs.push(solution._id);
        allSolutions.push({
          ...formatSolutionData(solution),
          problemId: problem._id,
        });
      }
    });
  });
  return allSolutions;
}

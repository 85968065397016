import React, { Component } from 'react';
import config from '../config';

export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoadingFont: true };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isLoadingFont: false }), 500);
  }

  render() {
    const { isLoadingFont } = this.state;
    if (isLoadingFont) {
      return null;
    }
    return (
      <div className="welcome-container full-screen">
        <div className="welcome">
          <h1 className="welcome-header">Ready to join the movement?</h1>
          <div className="continue-link-container welcome-link">
            <a className="welcome-link welcome-subheader" href="/items">
              {'Let\'s go'}
              <i className="fas fa-long-arrow-alt-right continue-arrow" />
            </a>
          </div>
          <div className="continue-link-container welcome-link">
            <a className="learn-more welcome-link" href="/about">or learn more</a>
          </div>
        </div>
        <div className="background-color" />
        <img src={`${config.cloudfront.URL}home.png`} alt="Welcome" />
      </div>
    );
  }
}

import React, { Component } from 'react';
import { PageHeader, Glyphicon } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { sortProblems, updateUserInfo } from '../utils/Api';
import { capitalizeFirstLetter, makeTitleCase } from '../utils/Resources';
import {
  getAllCategories as getAllCategoriesFromSanity,
  getAllProblems as getAllProblemsFromSanity,
  getAllSolutions as getAllSolutionsFromSanity,
} from '../utils/Sanity';
import CreateItemModal from '../components/modals/CreateItemModal';
import EditItemModal from '../components/modals/EditItemModal';
import DeleteItemModal from '../components/modals/DeleteItemModal';
import FlowCompleteModal from '../components/modals/FlowCompleteModal';
import ChecklistCompleteModal from '../components/modals/ChecklistCompleteModal';
import LineHeader from '../components/LineHeader';
import '../styles/Checklist.css';

export default class Checklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: 0,
      checkedValues: {},
      exceptions: {},
      userProblems: [],
      userSolutions: [],
      allCategories: [],
      allProblems: [],
      allSolutions: [],
      showCreateCategoryModal: false,
      showCreateProblemModal: false,
      showEditCategoryModal: false,
      showEditProblemModal: false,
      showDeleteCategoryModal: false,
      showDeleteProblemModal: false,
      showFlowCompleteModal: false,
      showCompleteModal: false,
      selectedCategoryId: undefined,
      userInfo: {},
      categoriesToDisplay: [],
      isLoading: true,
    };
    this.totalDataToLoad = 8;
  }

  async componentDidMount() {
    const { isAuthenticated, updateProgressState } = this.props;
    if (isAuthenticated) {
      try {
        const userInfo = await API.get('zwc-user-api', '/user-info');
        if (!userInfo.dismissedHelper) {
          this.setState({ userInfo, showFlowCompleteModal: true });
        }
        await updateProgressState(userInfo);
        this.getAllCategories();
        this.getAllProblems();
        this.getAllSolutions();
        this.getUserProblems();
        this.getUserSolutions();
        this.getCustomCategories();
        this.getCustomProblems();
        this.getCustomSolutions();
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
      }
    } else {
      this.setState({ isLoading: false });
    }
  }

  getAllCategories = () => {
    getAllCategoriesFromSanity().then((newCategoriesToDisplay) => {
      this.setState((prevState) => ({
        allCategories: [...newCategoriesToDisplay, ...prevState.allCategories],
        dataLoaded: prevState.dataLoaded + 1,
      }), this.getCategoriesToDisplay);
    });
  }

  getAllProblems = () => {
    getAllProblemsFromSanity().then((newProblemsToDisplay) => {
      this.setState((prevState) => ({
        allProblems: [...newProblemsToDisplay, ...prevState.allProblems],
        dataLoaded: prevState.dataLoaded + 1,
      }), this.getCategoriesToDisplay);
    });
  }

  getAllSolutions = () => {
    getAllSolutionsFromSanity().then((newSolutions) => {
      const solutionsToDisplay = newSolutions.filter((solution) => !solution.lowWaste);
      this.setState((prevState) => ({
        allSolutions: [...solutionsToDisplay, ...prevState.allSolutions],
        dataLoaded: prevState.dataLoaded + 1,
      }), this.getCategoriesToDisplay);
    });
  }

  getUserProblems = () => {
    API.get('zwc-user-api', '/user-problems').then((userProblems) => {
      const checkedValues = {};
      const exceptions = {};
      userProblems.forEach((userProblem) => {
        if (!userProblem.inExceptionsList) {
          checkedValues[userProblem.problemId] = userProblem.checked;
        }
        exceptions[userProblem.problemId] = userProblem.inExceptionsList;
      });
      this.setState((prevState) => ({
        checkedValues,
        exceptions,
        userProblems,
        dataLoaded: prevState.dataLoaded + 1,
      }), this.getCategoriesToDisplay);
    });
  }

  getUserSolutions = () => {
    API.get('zwc-user-api', '/user-solutions').then((userSolutions) => {
      this.setState((prevState) => ({
        userSolutions,
        dataLoaded: prevState.dataLoaded + 1,
      }), this.getCategoriesToDisplay);
    });
  }

  getCustomCategories = () => {
    API.get('zwc-user-api', '/user-custom-categories').then((customCategories) => {
      const customCategoriesWithTag = customCategories.map((category) => ({
        ...category, isCustom: true,
      })).sort((a, b) => a.createdAt - b.createdAt);
      this.setState((prevState) => ({
        allCategories: [...prevState.allCategories, ...customCategoriesWithTag],
        dataLoaded: prevState.dataLoaded + 1,
      }), this.getCategoriesToDisplay);
    });
  }

  getCustomProblems = () => {
    API.get('zwc-user-api', '/user-custom-problems').then((customProblems) => {
      const customProblemsWithTag = customProblems.map((problem) => ({
        ...problem, isCustom: true,
      }));
      this.setState((prevState) => ({
        allProblems: [...prevState.allProblems, ...customProblemsWithTag],
        dataLoaded: prevState.dataLoaded + 1,
      }), this.getCategoriesToDisplay);
    });
  }

  getCustomSolutions = () => {
    API.get('zwc-user-api', '/user-custom-solutions').then((customSolutions) => {
      const customSolutionsWithTag = customSolutions.map((solution) => ({
        ...solution, isCustom: true,
      }));
      this.setState((prevState) => ({
        allSolutions: [...prevState.allSolutions, ...customSolutionsWithTag],
        dataLoaded: prevState.dataLoaded + 1,
      }), this.getCategoriesToDisplay);
    });
  }

  getCategoriesToDisplay = () => {
    const {
      allProblems, userProblems, dataLoaded,
    } = this.state;
    if (dataLoaded === this.totalDataToLoad) {
      const categoriesToDisplay = this.getCategoriesToDisplayHelper(allProblems, userProblems);
      this.setState({ categoriesToDisplay, isLoading: false });
    }
  }

  getCategoriesToDisplayHelper = (allProblems, userProblems) => {
    const { allCategories } = this.state;
    const categoriesToDisplay = [];
    const userProblemIds = userProblems.map((problem) => problem.problemId);
    const sortedParentCategories = allCategories.filter((category) => !category.parentCategoryId);
    sortedParentCategories.forEach((category) => {
      let numProblemsForCategory = 0;
      // Get problems for category
      const sortedProblemsForCategory = sortProblems(allProblems.filter(
        (problem) => problem.categoryId === category.categoryId
        && userProblemIds.includes(problem.problemId)
        && !userProblems[userProblemIds.indexOf(problem.problemId)].inExceptionsList,
      ));
      numProblemsForCategory += sortedProblemsForCategory.length;
      // Get subcategories
      const sortedSubcategoriesWithProblems = [];
      const sortedSubcategories = allCategories.filter(
        (subcategory) => subcategory.parentCategoryId === category.categoryId,
      );
      sortedSubcategories.forEach((subcategory) => {
        // Get problems for subcategory
        const sortedProblemsForSubcategory = sortProblems(allProblems.filter(
          (problem) => problem.categoryId === subcategory.categoryId
          && userProblemIds.includes(problem.problemId)
          && !userProblems[userProblemIds.indexOf(problem.problemId)].inExceptionsList,
        ));
        if (sortedProblemsForSubcategory.length > 0) {
          sortedSubcategoriesWithProblems.push({
            category: subcategory,
            problems: sortedProblemsForSubcategory,
            subcategories: [],
          });
        }
        numProblemsForCategory += sortedProblemsForSubcategory.length;
      });
      if (category.isCustom || numProblemsForCategory > 0) {
        categoriesToDisplay.push({
          category,
          problems: sortedProblemsForCategory,
          subcategories: sortedSubcategoriesWithProblems,
        });
      }
    });
    return categoriesToDisplay;
  }

  getCategoryName = (categoryId) => {
    const { allCategories } = this.state;
    const category = allCategories.find((categoryInList) => (
      categoryInList.categoryId === categoryId
    ));
    return category.categoryName;
  }

  getProblemName = (problemId) => {
    const { allProblems } = this.state;
    const problem = allProblems.find((problemInList) => problemInList.problemId === problemId);
    return problem.problemName;
  }

  getSolutionName = (solutionId) => {
    const { allSolutions } = this.state;
    const solution = allSolutions.find((solutionInList) => (
      solutionInList.solutionId === solutionId
    ));
    return solution.solutionName;
  }

  onCheckboxClick = (event) => {
    const { id, checked } = event.target;
    const { checkedValues } = this.state;
    const updatedCheckedValues = checkedValues;
    updatedCheckedValues[id] = checked;
    this.setState({
      checkedValues: updatedCheckedValues,
      showCompleteModal: !Object.values(updatedCheckedValues).includes(false),
    });
    API.put('zwc-user-api', `/user-problem/${id}`, {
      body: {
        checked,
        inExceptionsList: false,
      },
    }, () => {
      API.get('zwc-user-api', '/user-problems').then((userProblems) => {
        this.setState({ userProblems });
      });
    });
  }

  renderCustomCategoryLink = () => (
    <li
      key="custom"
      className="checklist-category add-custom-category-link"
      onClick={() => this.setState({ showCreateCategoryModal: true })}
    >
      <i className="fas fa-plus" />
      Add category
    </li>
  );

  renderCustomProblemLink = (categoryId) => (
    <li
      key="custom"
      className="checklist-problem add-custom-problem-link"
      onClick={() => this.setState({
        showCreateProblemModal: true, selectedCategoryId: categoryId,
      })}
    >
      <i className="fas fa-plus" />
      Add problem
    </li>
  );

  renderCustomCategory = (categoryId) => (
    <span>
      <i
        className="fas fa-pencil-alt"
        onClick={() => this.setState({
          showEditCategoryModal: true, selectedCategoryId: categoryId,
        })}
      />
      <i
        className="fas fa-trash"
        onClick={() => this.setState({
          showDeleteCategoryModal: true, selectedCategoryId: categoryId,
        })}
      />
    </span>
  )

  renderCategory = (categoryDetails) => {
    const { category, problems, subcategories } = categoryDetails;
    const { categoryId, isCustom } = category;
    const isSubcategory = category.parentCategoryId;
    return (
      <div
        key={categoryId}
        className={isSubcategory ? 'checklist-subcategory' : 'checklist-category'}
      >
        {isSubcategory ? (
          <p className="checklist-subcategory-name">
            {makeTitleCase(this.getCategoryName(categoryId))}
            {isCustom && this.renderCustomCategory(categoryId)}
          </p>
        ) : (
          <LineHeader>
            {this.getCategoryName(categoryId)}
            {isCustom && this.renderCustomCategory(categoryId)}
          </LineHeader>
        )}
        <ul className="checklist-problems">
          {problems.map(this.renderProblem)}
          {this.renderCustomProblemLink(categoryId)}
        </ul>
        {subcategories.length > 0 && (
          <ul className="checklist-subcategories">
            {subcategories.map(this.renderCategory)}
          </ul>
        )}
      </div>
    );
  }

  renderProblem = (problem) => {
    const { checkedValues } = this.state;
    const { problemId, categoryId, isCustom } = problem;
    const categoryName = this.getCategoryName(categoryId);
    const problemName = this.getProblemName(problemId);
    return (
      <li key={problemId} className="checklist-problem">
        <input
          type="checkbox"
          id={problemId}
          onChange={this.onCheckboxClick}
          checked={checkedValues[problemId]}
        />
        <div className="checklist-problem-container">
          <div>
            {isCustom ? (
              <span>
                {capitalizeFirstLetter(problemName)}
                {this.renderSolutionsList(problemId)}
                <i
                  className="fas fa-pencil-alt"
                  onClick={() => this.setState({
                    showEditProblemModal: true, selectedProblemId: problemId,
                  })}
                />
                <i
                  className="fas fa-trash"
                  onClick={() => this.setState({
                    showDeleteProblemModal: true, selectedProblemId: problemId,
                  })}
                />
              </span>
            ) : (
              <a href={`/items/${categoryName.replace(/ /g, '_')}/${problemName.replace(/ /g, '_')}`}>
                {capitalizeFirstLetter(problemName)}
                {this.renderSolutionsList(problemId)}
              </a>
            )}
          </div>
        </div>
      </li>
    );
  };

  renderSolution = (solutionsForProblem, solution, index) => (
    <span key={solution.solutionId}>
      {capitalizeFirstLetter(this.getSolutionName(solution.solutionId))}
      {(index < solutionsForProblem.length - 1) && ', '}
    </span>
  )

  renderSolutionsList = (problemId) => {
    const { allSolutions, userSolutions } = this.state;
    const userSolutionsForProblem = userSolutions.filter((solution) => (
      allSolutions.find((solutionInList) => solutionInList.solutionId === solution.solutionId)
    )?.problemId === problemId);
    const hasSolutions = userSolutionsForProblem.length > 0;
    const sortedSolutions = userSolutionsForProblem.sort((a, b) => {
      const solution1 = allSolutions.find(
        (solutionInList) => solutionInList.solutionId === a.solutionId,
      );
      const solution2 = allSolutions.find(
        (solutionInList) => solutionInList.solutionId === b.solutionId,
      );
      return (solution1.solutionName.toLowerCase() < solution2.solutionName.toLowerCase()) ? -1 : 1;
    });
    return hasSolutions && (
      <span>
        <i className="fas fa-long-arrow-alt-right right-arrow" />
        {sortedSolutions.map((solution, index) => (
          this.renderSolution(sortedSolutions, solution, index)
        ))}
      </span>
    );
  }

  renderException = (problem) => {
    const { allProblems } = this.state;
    const { isCustom, categoryId } = allProblems.find(
      (problemInList) => problemInList.problemId === problem.problemId,
    );
    const categoryName = this.getCategoryName(categoryId);
    const problemName = this.getProblemName(problem.problemId);
    return (
      <li key={problem.problemId} className="checklist-problem exception">
        {isCustom ? <p>{capitalizeFirstLetter(problemName)}</p> : (
          <a href={`/items/${categoryName.replace(/ /g, '_')}/${problemName.replace(/ /g, '_')}`}>
            {capitalizeFirstLetter(problemName)}
          </a>
        )}
      </li>
    );
  }

  renderExceptionsList = () => {
    const { userProblems, exceptions } = this.state;
    const exceptionsList = userProblems
      .filter((userProblem) => exceptions[userProblem.problemId])
      .sort((a, b) => {
        const problem1 = this.getProblemName(a.problemId).toLowerCase();
        const problem2 = this.getProblemName(b.problemId).toLowerCase();
        return problem1 < problem2 ? -1 : 1;
      });
    return (exceptionsList.length > 0) && (
      <div className="exceptions-box">
        <PageHeader className="exceptions-header">Exceptions</PageHeader>
        <div className="exceptions-container">
          <ul className="exceptions">{exceptionsList.map(this.renderException)}</ul>
        </div>
      </div>
    );
  }

  saveCustomCategory = async (categoryName) => {
    const category = await API.post('zwc-user-api', '/user-custom-category', {
      body: { categoryName },
    });
    const categoryWithTag = { ...category, isCustom: true };
    const categoryForDisplay = { category: categoryWithTag, problems: [], subcategories: [] };
    return this.setState((prevState) => ({
      showCreateCategoryModal: false,
      allCategories: prevState.allCategories.concat([categoryWithTag]),
      categoriesToDisplay: prevState.categoriesToDisplay.concat([categoryForDisplay]),
    }));
  }

  saveCustomProblem = async (problemName, solutionNames) => {
    const { selectedCategoryId, allProblems, userProblems } = this.state;
    const problem = await API.post('zwc-user-api', '/user-custom-problem', {
      body: { categoryId: selectedCategoryId, problemName },
    });
    const problemWithTag = { ...problem, isCustom: true };
    const { problemId } = problem;
    const userProblem = await API.post('zwc-user-api', '/user-problem', {
      body: { problemId },
    });
    const newSolutionPromises = [];
    solutionNames.forEach((solutionName) => {
      if (solutionName !== '') {
        newSolutionPromises.push(API.post('zwc-user-api', '/user-custom-solution', {
          body: { problemId, solutionName },
        }));
      }
    });
    let newSolutions = await Promise.all(newSolutionPromises);
    newSolutions = newSolutions.map((solution) => ({ ...solution, isCustom: true }));
    const newUserSolutionPromises = [];
    newSolutions.forEach((solution) => {
      const { solutionId } = solution;
      newUserSolutionPromises.push(API.post('zwc-user-api', '/user-solution', {
        body: { solutionId },
      }));
    });
    const newUserSolutions = await Promise.all(newUserSolutionPromises);
    const newAllProblems = allProblems.concat([problemWithTag]);
    const newUserProblems = userProblems.concat([userProblem]);
    const categoriesToDisplay = this.getCategoriesToDisplayHelper(newAllProblems, newUserProblems);
    return this.setState((prevState) => ({
      showCreateProblemModal: false,
      selectedCategoryId: undefined,
      allProblems: newAllProblems,
      userProblems: newUserProblems,
      allSolutions: prevState.allSolutions.concat(newSolutions),
      userSolutions: prevState.userSolutions.concat(newUserSolutions),
      categoriesToDisplay,
    }));
  }

  updateCustomCategory = async (categoryName) => {
    const { allCategories, selectedCategoryId } = this.state;
    await API.put('zwc-user-api', `/user-custom-category/${selectedCategoryId}`, {
      body: { categoryName },
    });
    const index = allCategories.findIndex((category) => category.categoryId === selectedCategoryId);
    allCategories.splice(index, 1, { ...allCategories[index], categoryName });
    this.setState({
      showEditCategoryModal: false,
      selectedCategoryId: undefined,
      allCategories,
    });
  }

  updateCustomProblem = async (problemName, solutionNames) => {
    const {
      allProblems, selectedProblemId, allSolutions, userSolutions,
    } = this.state;
    await API.put('zwc-user-api', `/user-custom-problem/${selectedProblemId}`, {
      body: { problemName },
    });
    const index = allProblems.findIndex((problem) => problem.problemId === selectedProblemId);
    allProblems.splice(index, 1, { ...allProblems[index], problemName });
    const prevSolutionIds = userSolutions.filter((solution) => (
      allSolutions.find((solutionInList) => solutionInList.solutionId === solution.solutionId)
    )?.problemId === selectedProblemId).map((solution) => solution.solutionId);
    const prevSolutionPromises = [];
    prevSolutionIds.forEach((solutionId) => {
      prevSolutionPromises.push(API.del('zwc-user-api', `/user-solution/${solutionId}`));
      prevSolutionPromises.push(API.del('zwc-user-api', `/user-custom-solution/${solutionId}`));
    });
    await Promise.all(prevSolutionPromises);
    const newSolutionPromises = [];
    solutionNames.forEach((solutionName) => {
      if (solutionName !== '') {
        newSolutionPromises.push(API.post('zwc-user-api', '/user-custom-solution', {
          body: { problemId: selectedProblemId, solutionName },
        }));
      }
    });
    let newSolutions = await Promise.all(newSolutionPromises);
    newSolutions = newSolutions.map((solution) => ({ ...solution, isCustom: true }));
    const newUserSolutionPromises = [];
    newSolutions.forEach((solution) => {
      const { solutionId } = solution;
      newUserSolutionPromises.push(API.post('zwc-user-api', '/user-solution', {
        body: { solutionId },
      }));
    });
    await Promise.all(newUserSolutionPromises);
    const updatedUserSolutions = await API.get('zwc-user-api', '/user-solutions');
    this.setState((prevState) => ({
      showEditProblemModal: false,
      selectedProblemId: undefined,
      allProblems,
      allSolutions: prevState.allSolutions.concat(newSolutions),
      userSolutions: updatedUserSolutions,
    }));
  }

  deleteCustomCategory = () => {
    const {
      selectedCategoryId,
      allCategories,
      allProblems,
      userProblems,
      userSolutions,
      allSolutions,
      categoriesToDisplay,
    } = this.state;
    API.del('zwc-user-api', `/user-custom-category/${selectedCategoryId}`);
    allCategories.splice(
      allCategories.findIndex((category) => category.categoryId === selectedCategoryId),
      1,
    );
    const problemIdsForCategory = userProblems.filter((problem) => (
      allProblems.find((problemInList) => problemInList.problemId === problem.problemId)
    )?.categoryId === selectedCategoryId).map((problem) => problem.problemId);
    const promises = [];
    problemIdsForCategory.forEach((problemId) => {
      promises.push(API.del('zwc-user-api', `/user-problem/${problemId}`));
      promises.push(API.del('zwc-user-api', `/user-custom-problem/${problemId}`));
      const solutionIdsForProblem = userSolutions.filter((solution) => (
        allSolutions.find((solutionInList) => solutionInList.solutionId === solution.solutionId)
      )?.problemId === problemId).map((solution) => solution.solutionId);
      solutionIdsForProblem.forEach((solutionId) => {
        promises.push(API.del('zwc-user-api', `/user-solution/${solutionId}`));
        promises.push(API.del('zwc-user-api', `/user-custom-solution/${solutionId}`));
      });
    });
    Promise.all(promises);
    const index = categoriesToDisplay.findIndex(
      (category) => category.category.categoryId === selectedCategoryId,
    );
    categoriesToDisplay.splice(index, 1);
    return this.setState({
      showDeleteCategoryModal: false,
      selectedCategoryId: undefined,
      allCategories,
      categoriesToDisplay,
    });
  }

  deleteCustomProblem = async () => {
    const {
      selectedProblemId, allProblems, allSolutions, userSolutions,
    } = this.state;
    await API.del('zwc-user-api', `/user-problem/${selectedProblemId}`);
    API.del('zwc-user-api', `/user-custom-problem/${selectedProblemId}`);
    allProblems.splice(
      allProblems.findIndex((problemInList) => problemInList.problemId === selectedProblemId),
      1,
    );
    const userProblems = await API.get('zwc-user-api', '/user-problems');
    const solutionIdsForProblem = userSolutions.filter((solution) => (
      allSolutions.find((solutionInList) => solutionInList.solutionId === solution.solutionId)
    )?.problemId === selectedProblemId).map((solution) => solution.solutionId);
    const promises = [];
    solutionIdsForProblem.forEach((solutionId) => {
      promises.push(API.del('zwc-user-api', `/user-solution/${solutionId}`));
      promises.push(API.del('zwc-user-api', `/user-custom-solution/${solutionId}`));
    });
    Promise.all(promises);
    const categoriesToDisplay = this.getCategoriesToDisplayHelper(allProblems, userProblems);
    return this.setState({
      showDeleteProblemModal: false,
      selectedProblemId: undefined,
      allProblems,
      userProblems,
      categoriesToDisplay,
    });
  }

  renderCreateCategoryModal = () => {
    const { showCreateCategoryModal, categoriesToDisplay } = this.state;
    return (
      <CreateItemModal
        show={showCreateCategoryModal}
        hide={() => this.setState({ showCreateCategoryModal: false })}
        itemType="category"
        itemsOnPage={categoriesToDisplay.map((item) => item.category.categoryName.toLowerCase())}
        createItem={this.saveCustomCategory}
      />
    );
  }

  renderCreateProblemModal = () => {
    const {
      showCreateProblemModal, selectedCategoryId, userProblems, allProblems,
    } = this.state;
    const userProblemIds = userProblems.map((problem) => problem.problemId);
    const itemsOnPage = allProblems
      .filter((problem) => (
        problem.categoryId === selectedCategoryId && userProblemIds.includes(problem.problemId)
      ))
      .map((item) => item.problemName.toLowerCase());
    return (
      <CreateItemModal
        show={showCreateProblemModal}
        hide={() => this.setState({ showCreateProblemModal: false })}
        itemType="problem"
        itemsOnPage={itemsOnPage}
        createItem={this.saveCustomProblem}
      />
    );
  }

  renderEditCategoryModal = () => {
    const { showEditCategoryModal, selectedCategoryId, categoriesToDisplay } = this.state;
    return (
      <EditItemModal
        show={showEditCategoryModal}
        hide={() => this.setState({ showEditCategoryModal: false })}
        itemType="category"
        name={selectedCategoryId ? this.getCategoryName(selectedCategoryId) : ''}
        itemsOnPage={categoriesToDisplay.map((item) => item.category.categoryName.toLowerCase())}
        // TODO check itemsOnPage in EditItemModal
        updateItemName={this.updateCustomCategory}
      />
    );
  }

  renderEditProblemModal = () => {
    const {
      showEditProblemModal,
      selectedProblemId,
      userProblems,
      allProblems,
      userSolutions,
      allSolutions,
    } = this.state;
    const userProblemIds = userProblems.map((problem) => problem.problemId);
    const itemsOnPage = allProblems
      .filter((problem) => userProblemIds.includes(problem.problemId))
      .map((item) => item.problemName.toLowerCase());
    const userSolutionIds = userSolutions.map((solution) => solution.solutionId);
    const solutionNames = allSolutions.filter((solution) => (
      solution.problemId === selectedProblemId && userSolutionIds.includes(solution.solutionId)
    )).map((solution) => solution.solutionName).sort(
      (a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1),
    );
    return (
      <EditItemModal
        show={showEditProblemModal}
        hide={() => this.setState({ showEditProblemModal: false })}
        itemType="problem"
        name={selectedProblemId ? this.getProblemName(selectedProblemId) : ''}
        solutionNames={solutionNames}
        itemsOnPage={itemsOnPage} // TODO check itemsOnPage in EditItemModal
        updateItemName={this.updateCustomProblem}
      />
    );
  }

  renderDeleteCategoryModal = () => {
    const { showDeleteCategoryModal } = this.state;
    return (
      <DeleteItemModal
        show={showDeleteCategoryModal}
        hide={() => this.setState({ showDeleteCategoryModal: false })}
        itemType="category"
        deleteItem={this.deleteCustomCategory}
      />
    );
  }

  renderDeleteProblemModal = () => {
    const { showDeleteProblemModal } = this.state;
    return (
      <DeleteItemModal
        show={showDeleteProblemModal}
        hide={() => this.setState({ showDeleteProblemModal: false })}
        itemType="problem"
        deleteItem={this.deleteCustomProblem}
      />
    );
  }

  renderFlowCompleteModal = () => {
    const { userInfo, showFlowCompleteModal } = this.state;
    return (
      <FlowCompleteModal
        show={showFlowCompleteModal}
        hide={() => {
          updateUserInfo({ ...userInfo, dismissedHelper: true });
          this.setState({ showFlowCompleteModal: false });
        }}
      />
    );
  }

  renderCompleteModal = () => {
    const { showCompleteModal } = this.state;
    return (
      <ChecklistCompleteModal
        show={showCompleteModal}
        hide={() => this.setState({ showCompleteModal: false })}
      />
    );
  }

  render() {
    const { isAuthenticated, progressStage } = this.props;
    const { userProblems, categoriesToDisplay, isLoading } = this.state;
    let linkHref = '/intro';
    let linkText = 'Add items to checklist';
    if (categoriesToDisplay.length > 0) {
      if (progressStage === 'startedProblemsFlow') {
        linkHref = '/checklist-builder';
        linkText = 'Continue adding items to checklist';
      } else {
        linkHref = '/items';
        linkText = 'Add more items to checklist';
      }
    }
    const twoColumns = userProblems.length > 10;
    return (
      <div>
        <PageHeader className="checklist-page-header">Your Zero Waste Checklist</PageHeader>
        {isAuthenticated && categoriesToDisplay.length > 0 && (
          <div className="checklist-subheader">
            Click on a problem to pick out your favorite zero-waste solutions.
          </div>
        )}
        {isLoading ? <Glyphicon glyph="refresh" className="spinning page-spinner" /> : (
          <div className="checklist-container">
            {isAuthenticated && categoriesToDisplay.length > 0 ? (
              <div className="checklist">
                <div>
                  <div id="checklist" className={`checklist-categories${twoColumns ? ' two-columns' : ''}`}>
                    {categoriesToDisplay.map(this.renderCategory)}
                    {this.renderCustomCategoryLink()}
                    {this.renderExceptionsList()}
                  </div>
                  {this.renderCreateCategoryModal()}
                  {this.renderCreateProblemModal()}
                  {this.renderEditCategoryModal()}
                  {this.renderEditProblemModal()}
                  {this.renderDeleteCategoryModal()}
                  {this.renderDeleteProblemModal()}
                  {this.renderFlowCompleteModal()}
                  {this.renderCompleteModal()}
                </div>
              </div>
            ) : <h3 className="no-items-yet">No items yet!</h3>}
          </div>
        )}
        {!isLoading && <div className="add-items-link"><a href={linkHref}>{linkText}</a></div>}
      </div>
    );
  }
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Welcome from './containers/Welcome';
import Intro from './components/Intro';
import About from './components/About';
import Database from './containers/Database';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import Contact from './containers/Contact';
import ChecklistBuilder from './containers/ChecklistBuilder';
import Auth from './containers/auth/Auth';
import Checklist from './containers/Checklist';
import ProblemPage from './containers/ProblemPage';
import NotFound from './containers/NotFound';
import PrivacyPolicy from './components/PrivacyPolicy';
import AppliedRoute from './components/AppliedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Welcome} props={childProps} />
    <AppliedRoute path="/intro" exact component={Intro} props={childProps} />
    <AppliedRoute path="/about" exact component={About} props={childProps} />
    <AppliedRoute path="/items" exact component={Database} props={childProps} />
    <AppliedRoute path="/items/:category/:problem" exact component={ProblemPage} props={childProps} />
    <AppliedRoute path="/checklist" exact component={Checklist} props={childProps} />
    <AppliedRoute path="/blog" exact component={Blog} props={childProps} />
    <AppliedRoute path="/blog/:post" exact component={BlogPost} props={childProps} />
    <AppliedRoute path="/contact" exact component={Contact} props={childProps} />
    <AppliedRoute path="/privacy-policy" exact component={PrivacyPolicy} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Auth} props={childProps} />
    <AuthenticatedRoute path="/checklist-builder" exact component={ChecklistBuilder} props={childProps} />
    <Route component={NotFound} />
  </Switch>
);

import React from 'react';
import { Modal } from 'react-bootstrap';

const content = require('../../content/modals.json');

export default (props) => {
  const { show, hide } = props;
  return (
    <Modal className="helper-modal" show={show} onHide={hide}>
      <Modal.Header closeButton className="helper-modal-header">
        <Modal.Title className="complete-modal-title">{content.allBoxesChecked.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="helper-modal-body">
        <p>{content.allBoxesChecked.paragraphs[0]}</p>
        <p className="tips">{content.allBoxesChecked.paragraphs[1]}</p>
        <ul className="helper-notes">
          {content.allBoxesChecked.tips.map((tip) => <li key={tip}><p>{tip}</p></li>)}
        </ul>
        <p
          className="continue-link modal-action-link return-to-checklist"
          onClick={hide}
        >
          Return to checklist
        </p>
      </Modal.Body>
    </Modal>
  );
};

import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { handleUserAuth } from '../../utils/Resources';
import LoginForm from '../../components/auth/LoginForm';

const content = require('../../content/auth.json');

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      userHasAuthenticated,
      updateProgressState,
      switchToPage,
      email,
      password,
      closeModal,
    } = this.props;
    this.setState({ isLoading: true });
    try {
      await Auth.signIn(email, password);
      handleUserAuth(updateProgressState, userHasAuthenticated, closeModal);
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        await Auth.resendSignUp(email);
        switchToPage('SignUpConfirmation');
      } else {
        if (e.code === 'UserNotFoundException') {
          alert(content.errors.userDoesNotExist);
        } else if (e.code === 'NotAuthorizedException') {
          alert(content.errors.incorrectPassword);
        } else {
          alert(e.message);
        }
        this.setState({ isLoading: false });
      }
    }
  }

  validateForm = () => {
    const { email, password } = this.props;
    return email.length > 0 && password.length > 0;
  }

  render() {
    const {
      handleChange,
      switchToPage,
      email,
      password,
      passwordWasReset,
      headerClass,
    } = this.props;
    const { isLoading } = this.state;
    return (
      <LoginForm
        email={email}
        password={password}
        passwordWasReset={passwordWasReset}
        handleChange={handleChange}
        handleSubmit={this.handleSubmit}
        validateForm={this.validateForm}
        switchToPage={switchToPage}
        isLoading={isLoading}
        headerClass={headerClass}
      />
    );
  }
}

import React, { Component } from 'react';
import { Glyphicon, Button, FormControl } from 'react-bootstrap';
import moment from 'moment';
import config from '../config';
import '../styles/Blog.css';
import PortableText from './PortableText';

export default class BlogPost extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    const { match } = this.props;
    const { post } = match.params;

    const query = `
      query {
        allBlogPost {
          title
          date
          image {
            asset {
              url
            }
          }
          articleRaw
        }
      }
    `;
    const params = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    };
    fetch(config.sanity.URL, params)
      .then((res) => res.json())
      .then(({ data }) => {
        this.setState({
          isLoading: false,
          blogPost: data.allBlogPost.find(
            (postInList) => postInList.title.toLowerCase() === post.replace(/_/g, ' ').replace(/%3F/, '?').toLowerCase(),
          ),
        });
      });
  }

  render() {
    const { isLoading, blogPost } = this.state;
    return isLoading || !blogPost ? <Glyphicon glyph="refresh" className="spinning page-spinner" /> : (
      <div className="info-page">
        <div key={blogPost.title} className="blog-post">
          <h1>{blogPost.title}</h1>
          <p>{moment(blogPost.date).format('MMMM D, YYYY')}</p>
          <img
            className="blog-post-img"
            src={blogPost.image.asset.url}
            alt={blogPost.title}
          />
          <div className="blog-post-content">
            <PortableText text={blogPost.articleRaw} />
          </div>
          <hr />
        </div>
        <div className="subscribe">
          <div id="mc_embed_signup">
            <form action="https://gmail.us5.list-manage.com/subscribe/post?u=5a6e0a9bd3b41cad5f8703aa9&amp;id=ec3f45d788" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
              <div id="mc_embed_signup_scroll" className="mailchimp">
                <h3 className="subscribe-header">Get the ZWC blog posts sent straight to your inbox.</h3>
                <div className="subscribe-input-container">
                  <div className="mc-field-group">
                    <FormControl type="email" name="EMAIL" className="required email" id="mce-email" placeholder="Email Address" />
                  </div>
                  <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{ display: 'none' }} />
                    <div className="response" id="mce-success-response" style={{ display: 'none' }} />
                  </div>
                  <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                    <input type="text" name="b_5a6e0a9bd3b41cad5f8703aa9_ec3f45d788" tabIndex="-1" />
                  </div>
                  <Button type="submit" name="subscribe" id="mc-embedded-subscribe" className="button">Subscribe</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

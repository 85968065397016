import React from 'react';
import { Modal } from 'react-bootstrap';
import ReactHTMLParser from 'react-html-parser';

export default (props) => {
  const { show, hide, text } = props;
  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton className="details-header" />
      <Modal.Body><p>{ReactHTMLParser(text)}</p></Modal.Body>
    </Modal>
  );
};

import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import LoaderButton from '../LoaderButton';
import { getFormFieldSize } from '../../utils/Resources';

const content = require('../../content/auth.json');

export default (props) => {
  const {
    email,
    handleChange,
    handleSubmit,
    validateForm,
    isSendingCode,
    headerClass,
  } = props;
  const bsSize = getFormFieldSize();
  return (
    <div className="reset-password">
      <h1 className={`auth-header ${headerClass}`}>{content.resetPasswordHeader}</h1>
      <p className="auth-subheader">{content.resetPasswordSubheader}</p>
      <form onSubmit={handleSubmit}>
        <FormGroup bsSize={bsSize} controlId="email">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={handleChange}
          />
        </FormGroup>
        <LoaderButton
          className="auth-button"
          block
          type="submit"
          bsSize={bsSize}
          loadingText="Sending…"
          text="Send code"
          isLoading={isSendingCode}
          disabled={!validateForm()}
        />
      </form>
    </div>
  );
};

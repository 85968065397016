import React from 'react';
import { Modal } from 'react-bootstrap';
import ReactHTMLParser from 'react-html-parser';

const content = require('../../content/modals.json');

export default (props) => {
  const { show, hide } = props;
  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title className="preview-modal-title">{content.welcome.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content.welcome.paragraphs.map(
          (paragraph) => <p key={paragraph}>{ReactHTMLParser(paragraph)}</p>,
        )}
      </Modal.Body>
    </Modal>
  );
};

import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import LoaderButton from './LoaderButton';
import { getFormFieldSize } from '../utils/Resources';
import '../styles/ContactForm.css';

export default (props) => {
  const {
    pageHeader,
    pageSubheader,
    name,
    email,
    message,
    isSending,
    handleChange,
    handleSubmit,
    validateForm,
  } = props;
  const bsSize = getFormFieldSize();
  return (
    <div className="info-page contact-page">
      <div>
        <h1>{pageHeader}</h1>
        <div className="contact-note-container">
          <p className="contact-note">{pageSubheader}</p>
        </div>
        <form onSubmit={handleSubmit} className="contact-form">
          <FormGroup controlId="name" bsSize={bsSize}>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              autoFocus
              type="name"
              value={name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup controlId="email" bsSize={bsSize}>
            <ControlLabel>Email</ControlLabel>
            <FormControl
              type="email"
              value={email}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup controlId="message" bsSize={bsSize}>
            <ControlLabel>Message</ControlLabel>
            <FormControl
              componentClass="textarea"
              rows={8}
              value={message}
              onChange={handleChange}
            />
          </FormGroup>
          <LoaderButton
            className="auth-button"
            block
            bsSize={bsSize}
            disabled={!validateForm()}
            type="submit"
            isLoading={isSending}
            text="Send"
            loadingText="Sending..."
          />
        </form>
      </div>
    </div>
  );
};

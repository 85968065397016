import React from 'react';
import { Modal } from 'react-bootstrap';

const content = require('../../content/modals.json');

export default (props) => {
  const { show, hide } = props;
  const numParagraphs = content.flowComplete.paragraphs.length;
  return (
    <Modal className="helper-modal" show={show} onHide={hide}>
      <Modal.Header closeButton className="helper-modal-header">
        <Modal.Title className="helper-modal-title">{content.flowComplete.header}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="helper-modal-body">
        {content.flowComplete.paragraphs.map((paragraph, index) => (
          <p key={paragraph} className={index === numParagraphs - 1 ? '' : 'helper-modal-paragraph'}>
            {paragraph}
          </p>
        ))}
      </Modal.Body>
    </Modal>
  );
};

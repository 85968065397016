import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import Routes from './Routes';
import NavBar from './containers/NavBar';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      progressStage: undefined,
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentAuthenticatedUser();
      this.userHasAuthenticated(true);
    } catch (e) {
      if (e !== 'not authenticated') {
        console.log(e); // eslint-disable-line no-console
      }
    }
    this.setState({ isAuthenticating: false });
  }

  updateProgressState = (userInfo) => this.setState({ progressStage: userInfo.progressStage });

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async () => {
    const { history } = this.props;
    await Auth.signOut();
    this.userHasAuthenticated(false);
    history.push('/');
  }

  render() {
    const { isAuthenticating, isAuthenticated, progressStage } = this.state;
    const childProps = {
      isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      progressStage,
      updateProgressState: this.updateProgressState,
    };
    return (
      !isAuthenticating
      && (
      <div>
        <NavBar
          onHomePage={window.location.pathname === '/'}
          isAuthenticated={isAuthenticated}
          handleLogout={this.handleLogout}
        />
        <div className="container">
          <Routes childProps={childProps} />
        </div>
      </div>
      )
    );
  }
}

export default withRouter(App);

import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import LoaderButton from '../LoaderButton';
import { getFormFieldSize } from '../../utils/Resources';
import '../../styles/LoginForm.css';

const content = require('../../content/auth.json');

export default (props) => {
  const {
    email,
    password,
    passwordWasReset,
    handleChange,
    handleSubmit,
    validateForm,
    switchToPage,
    isLoading,
    headerClass,
  } = props;
  const bsSize = getFormFieldSize();
  return (
    <div className="login">
      <h1 className={`auth-header ${headerClass}`}>
        {!passwordWasReset ? content.loginHeader : content.loginHeader2}
      </h1>
      <p className="auth-subheader">
        {!passwordWasReset ? content.loginSubheader : content.loginSubheader2}
      </p>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize={bsSize}>
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type="email"
            value={email}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize={bsSize}>
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={password}
            onChange={handleChange}
            type="password"
          />
        </FormGroup>
        {!passwordWasReset && (
          <FormGroup className="forgot-password-container">
            <ControlLabel className="forgot-password-label">
              <p className="forgot-password-link" onClick={() => switchToPage('ResetPassword')}>
                Forgot password?
              </p>
            </ControlLabel>
          </FormGroup>
        )}
        <LoaderButton
          className="auth-button login-button"
          block
          bsSize={bsSize}
          disabled={!validateForm()}
          type="submit"
          isLoading={isLoading}
          text="Log in"
          loadingText="Logging in…"
        />
        {!passwordWasReset && (
          <div className="continue-link auth-link" onClick={() => switchToPage('SignUp')}>
            Don&apos;t have an account?
          </div>
        )}
      </form>
    </div>
  );
};

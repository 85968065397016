import React, { Component } from 'react';
import { FormGroup, FormControl, Modal } from 'react-bootstrap';
import { getFormFieldSize } from '../../utils/Resources';
import LoaderButton from '../LoaderButton';
import '../../styles/CreateItemModal.css';

export default class CreateItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      solutionNames: [''],
      isSaving: false,
      displayError: false,
    };
  }

  handleChange = (event) => {
    if (event.target.value.length <= 35) {
      this.setState({ name: event.target.value });
    }
  }

  handleSolutionChange = (event, index) => {
    if (event.target.value.length <= 35) {
      const { solutionNames } = this.state;
      solutionNames.splice(index, 1, event.target.value);
      this.setState({ solutionNames });
    }
  }

  addSolutionInput = () => {
    this.setState((prevState) => ({ solutionNames: prevState.solutionNames.concat(['']) }));
  }

  removeSolutionInput = (index) => {
    const { solutionNames } = this.state;
    solutionNames.splice(index, 1);
    this.setState({ solutionNames });
  }

  validateForm = () => {
    const { name } = this.state;
    return name.length > 0;
  }

  createItem = (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });
    const { createItem, hide, itemsOnPage } = this.props;
    const { name, solutionNames } = this.state;
    if (itemsOnPage.includes(name.toLowerCase())) {
      this.setState({ displayError: true, isSaving: false });
    } else {
      this.setState({ displayError: false });
      createItem(name, solutionNames).then(() => {
        this.setState({
          name: '',
          solutionNames: [''],
          isSaving: false,
          displayError: false,
        }, hide);
      });
    }
  }

  render() {
    const { show, hide, itemType } = this.props;
    const {
      name, solutionNames, isSaving, displayError,
    } = this.state;
    const bsSize = getFormFieldSize();
    return (
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>{`Add ${itemType}:`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.createItem}>
            <FormGroup controlId="itemName" bsSize={bsSize}>
              <FormControl
                autoFocus
                type="text"
                value={name}
                onChange={this.handleChange}
              />
            </FormGroup>
            {itemType === 'problem' && (
              <div>
                <h4 className="add-solution">Add solutions:</h4>
                {solutionNames.map((solutionName, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="custom-solution">
                    <FormGroup controlId="solutionName" bsSize={bsSize} className="custom-solution-input">
                      <FormControl
                        type="text"
                        value={solutionName}
                        onChange={(event) => this.handleSolutionChange(event, index)}
                      />
                    </FormGroup>
                    <span className="delete-custom-solution">
                      <i className="fas fa-trash" onClick={() => this.removeSolutionInput(index)} />
                    </span>
                  </div>
                ))}
                <div className="add-another-solution" onClick={this.addSolutionInput}>
                  <i className="fas fa-plus" />
                  Add another solution
                </div>
              </div>
            )}
            <p className={`${displayError ? 'error' : 'hidden-error'}${itemType === 'problem' ? ' problem-error' : ''}`}>
              {`A ${itemType} with this name already exists.`}
            </p>
            <div className="save-custom-container">
              <LoaderButton
                className="save-custom-button"
                block
                bsSize={bsSize}
                disabled={!this.validateForm()}
                type="submit"
                text="Save"
                loadingText="Saving..."
                isLoading={isSaving}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}
